import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import {
  Form,
  Tag,
  Checkbox,
  Select,
  DatePicker,
  Button,
  Table,
  Card
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

import moment from 'moment';

import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from "react-csv";


interface Option {
  value: string,
  label: ReactElement | string
};

interface Stock {
  tradingfloordate: string,
  markettype: number,
  shortname: string,
  openingprice: string,
  closingprice: string,
  minimumprice: string,
  maximumprice: string,
  averageprice: string,
  numberoftrades: number,
  financialtraded: string,
  oscillation: number
};

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

const CustomTooltip = (props: any) => {
  const { active, payload, label } = props;
  if (active) {
    return (
      <Card size="small" title={moment(label).format('DD MMM YYYY')}>
        {payload && <p>R$ {payload[0].value}</p>}
      </Card>
    );
  }

  return null;
};

const Trades: FunctionComponent<Props> = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const [assetData, setAssetData] = useState<Array<Option>>([]);
  const [bdiData, setBDIData] = useState<Array<Option>>([]);
  const [marketData, setMarketData] = useState<Array<Option>>([]);

  const [stockData, setStockData] = useState<Array<Stock>>([]);

  const { firstDate, latestDate } = props.dates;

  useEffect(() => {
    StockApiProvider.get('assets/')
      .then((r: any) => {
        const { data } = r;
        const tmp_options: Option[] = data.map((asset: any) => ({
          value: asset.tradingcode,
          label: (
            <>
              <Tag>{asset.tradingcode}</Tag>
              <span>{asset.shortname}</span>
            </>
          )
        }));
        setAssetData([...tmp_options]);
      });
    // TIPO NEGOCIO E TIPO MERCADO
    Promise.all([StockApiProvider.get('class/bdi/'), StockApiProvider.get('class/market/')])
      .then(r => {
        const bdilist = r[0].data;
        const marketlist = r[1].data;

        if (bdilist.length && marketlist.length) {
          let tmp_bdi: Array<Option> = bdilist[0].children.map((bdi: any) => ({ value: bdi.name, label: bdi.value }));
          let tmp_market: Array<Option> = marketlist[0].children.map((market: any) => ({ value: market.name, label: market.value }));
          setBDIData([...tmp_bdi]);
          setMarketData([...tmp_market]);
        }

      })
      .catch(err => { });
  }, []);

  const columns: any = [
    {
      title: 'Data',
      dataIndex: 'tradingfloordate',
      render: (date: string) => date.split('-').reverse().join('/'),
      sorter: (a: any, b: any) => new Date(a.tradingfloordate).getTime() - new Date(b.tradingfloordate).getTime(),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Negócios',
      dataIndex: 'numberoftrades',
      sorter: (a: any, b: any) => a.numberoftrades - b.numberoftrades,
      render: (value: number) => value.toLocaleString('pt-BR'),
    },
    {
      title: 'Volume',
      dataIndex: 'financialtraded',
      sorter: (a: any, b: any) => a.financialtraded - b.financialtraded,
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Abertura',
      dataIndex: 'openingprice',
      sorter: (a: any, b: any) => a.openingprice - b.openingprice,
      render: (value: number) => `R$ ${value.toFixed(2)}`.replace(".", ","),
    },
    {
      title: 'Mínimo',
      dataIndex: 'minimumprice',
      sorter: (a: any, b: any) => a.minimumprice - b.minimumprice,
      render: (value: number) => `R$ ${value.toFixed(2)}`.replace(".", ","),
    },
    {
      title: 'Médio',
      dataIndex: 'averageprice',
      sorter: (a: any, b: any) => a.averageprice - b.averageprice,
      render: (value: number) => `R$ ${value.toFixed(2)}`.replace(".", ","),
    },
    {
      title: 'Máximo',
      dataIndex: 'maximumprice',
      sorter: (a: any, b: any) => a.maximumprice - b.maximumprice,
      render: (value: number) => `R$ ${value.toFixed(2)}`.replace(".", ","),
    },
    {
      title: 'Fechamento',
      dataIndex: 'closingprice',
      sorter: (a: any, b: any) => a.closingprice - b.closingprice,
      render: (value: number, record: any) => {

        let { oscillation, closingprice } = record;
        closingprice = parseFloat(closingprice);
        oscillation = parseFloat(oscillation);

        const prev_day = moment(record.tradingfloordate, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD');
        const prev_index: number = stockData.findIndex((value: Stock) => value.tradingfloordate === prev_day);

        if (prev_index > 0) {
          const prev_record: Stock = stockData[prev_index];

          const epsilon = 0.1;
          const expected_price = closingprice / (1+oscillation/100);
          const previous_price = parseFloat(prev_record.closingprice);

          // Encontrar divergência
          if (!(Math.abs(expected_price-previous_price) < epsilon)) {
            console.log(`Closing: ${closingprice}, Expected: ${expected_price}, Previous: ${previous_price}`);
            return `R$ ${value.toFixed(2)}*`.replace(".", ",");
          }
        }
        return `R$ ${value.toFixed(2)}`.replace(".", ",");
      },
    },
    {
      title: '%Osc',
      dataIndex: 'oscillation',
      sorter: (a: any, b: any) => a.oscillation - b.oscillation,
    },
    {
      title: '%Osc.Ac',
      dataIndex: 'acc_oscillation',
    },
  ];

  const onFinish = (values: any) => {

    const start_date: string = moment(values.daterange[0]._d).format('YYYY-MM-DD');
    const end_date: string = moment(values.daterange[1]._d).format('YYYY-MM-DD');
    const corrected: boolean = values.corrected;
    const bdi: string = values.bdi;
    const market: string = values.market;

    let query_url: string = '';

    if (bdi === '02' && market === '010')
      query_url = `dailycote/${values.tradingcode}?start=${start_date}&end=${end_date}&corrected=${corrected}`;
    else
      query_url = `otherbusiness/${values.tradingcode}?start=${start_date}&end=${end_date}&bdi=${bdi}&market=${market}`;

    setLoading(true);
    StockApiProvider.get(query_url)
      .then((r: any) => {
        const { data } = r;
        let added_data: Array<any> = [];
        for (let i = data.length - 1; i >= 0; i--) {
          let acc_oscillation: number = ((data[i].closingprice / data[data.length - 1].closingprice) - 1) * 100
          acc_oscillation = parseFloat(acc_oscillation.toFixed(2))
          added_data.push({ ...data[i], acc_oscillation: acc_oscillation })
        }
        added_data.reverse();
        setStockData([...added_data]);
        setLoading(false);
      }).catch(() => setLoading(false));
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        layout="inline"
        style={{ height: '60px' }}>
        <Form.Item
          name="daterange"
          initialValue={[moment(firstDate), moment(latestDate)]}
          rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
          <DatePicker.RangePicker
            ranges={{
              'Hoje': [moment(), moment()],
              'Mês atual': [moment().startOf('month'), moment().endOf('month')],
              'Ano atual': [moment().startOf('year'), moment().endOf('year')],
            }}
            format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="tradingcode"
          rules={[{ required: true, message: 'Por favor inserir o código BDI' }]}>
          <Select
            showSearch
            showArrow={false}
            style={{ width: 250 }}
            placeholder="Código BDI"
            optionFilterProp="value"
            filterOption={(input, option: any) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {assetData.map((option: Option) => {
              return <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
            })}
          </Select>
        </Form.Item>

        <Form.Item name="bdi" initialValue="02">
          <Select style={{ width: 200 }}>
            {bdiData.map((op: Option) => <Select.Option key={op.value} value={op.value}>{op.label}</Select.Option>)}
          </Select>
        </Form.Item>

        <Form.Item name="market" initialValue="010">
          <Select style={{ width: 200 }}>
            {marketData.map((op: Option) => <Select.Option key={op.value} value={op.value}>{op.label}</Select.Option>)}
          </Select>
        </Form.Item>

        <Form.Item name="corrected" valuePropName="checked" initialValue={true}>
          <Checkbox>Preço corrigido</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary">
            Pesquisar
        </Button>
        </Form.Item>
      </Form>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={stockData}
          margin={{
            top: 20, right: 20, left: 20, bottom: 20,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="tradingfloordate"
            tickFormatter={tick => moment(tick).format("DD MMM")} />
          <YAxis
            type="number"
            allowDecimals={true}
            tickFormatter={tick => `R$ ${tick}`}
            interval="preserveStartEnd"
            domain={['dataMin', 'dataMax']} />
          <Tooltip content={CustomTooltip} />
          <Line type="linear" dataKey="closingprice" stroke="#82ca9d" dot={false} />
        </LineChart>
      </ResponsiveContainer>
      <Table
        title={() => (
          <Button
            disabled={stockData.length === 0}
            type="primary"
            icon={<DownloadOutlined />}>
            <CSVLink data={stockData} filename={"Trades.csv"} style={{color: 'inherit'}}>Download</CSVLink>
          </Button>
        )}
        loading={loading}
        dataSource={stockData}
        columns={columns} />
    </>
  );
}

export default Trades;
