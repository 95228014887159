import React, {
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css';

import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Space,
  Divider,
  message
} from 'antd';
import {
  SaveOutlined,
  EditOutlined,
} from '@ant-design/icons';


import StockApiProvider from '../../../providers/StockApiProvider';

interface Doc {
  id?: string,
  name?: string,
  value?: string,
}

const Documentation: FunctionComponent = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const [docs, setDocs] = useState<Array<any>>([]);
  const [currentDocs, setCurrentDocs] = useState<Doc>();

  const [addForm] = Form.useForm();

  const mdParser = new MarkdownIt();

  const fetchDocs = () => {
    StockApiProvider.get(`class/documentacao/`)
      .then((r: any) => {
        const tmpDocs = r.data[0].children;
        setDocs([...tmpDocs]);
      });
  };

  const updateDocs = () => {
    setLoading(true);
    StockApiProvider.patch(`class/${currentDocs?.id}/`, {...currentDocs})
    .then(res => {
      message.success('Dados atualizados');
      fetchDocs();
    })
    .catch(err => {
      message.error('Houve um erro ao salvar os dados')
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchDocs();
  }, []);

  const handleSelectDocs = (value: string) => {
    setCurrentDocs(docs.find((e: any) => e.id === value))
  };

  const handleChangeDocs = (obj: any) => {
    const text = obj.text;
    let current: Doc = {...currentDocs};
    setCurrentDocs({...current, value: text})
  };

  const addNewDocs = () => {
    setLoading(true);

    addForm.validateFields()
    .then(values => {
      const { id, name } = values;
      StockApiProvider.post('class/', {
        id: `documentacao_${id}`,
        name,
        value: `# ${name}`,
        parent: 'documentacao'
      })
      .then(res => {
        message.success('Nova entrada adicionada');
        fetchDocs();
        setCurrentDocs({ id: `documentacao_${id}`, name });
      })
      .catch(err => {
        message.error('Houve um erro ao enviar os dados')
      });
  
    })
    setModalVisible(false);
    setLoading(false);
  };

  return (
    <>
      <Space>
        <Select
          style={{ minWidth: 200 }}
          showSearch
          showArrow={false}
          placeholder="Documentação"
          optionFilterProp="value"
          onChange={handleSelectDocs}
          filterOption={(input, option: any) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
          {docs.map((option: any) => {
            return (
              <Select.Option
                key={option.id}
                value={option.id}>
                  {option.name}
              </Select.Option>
            );
          })}
        </Select>
        <Button
          loading={loading}
          type="primary"
          icon={<EditOutlined />}
          onClick={() => setModalVisible(true)}>
          Adicionar nova
        </Button>
        {currentDocs &&
        <Button
          loading={loading}
          type="primary"
          icon={<SaveOutlined />}
          onClick={updateDocs}>
          Salvar mudanças
        </Button>}
      </Space>
      <Divider />
      <Modal
        title="Adicionar nó raiz"
        visible={modalVisible}
        confirmLoading={loading}
        onCancel={() => setModalVisible(false)}
        onOk={addNewDocs}>
        <Form form={addForm}>
          <Form.Item name="id" rules={[{ required: true, message: 'ID inválido' }]}>
            <Input placeholder="id" />
          </Form.Item>
          <Form.Item name="name" rules={[{ required: true, message: 'Nome inválido' }]}>
            <Input placeholder="Nome" />
          </Form.Item>
        </Form>
      </Modal>
      {currentDocs &&
      <MdEditor
        config={{ menu: false, md: false, html: true }}
        value={currentDocs?.value}
        style={{ height: "500px" }}
        renderHTML={(text) => mdParser.render(text)}
        onChange={handleChangeDocs}
      />}
    </>
  );
}

export default Documentation;
