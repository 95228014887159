import React, {
  FunctionComponent,
  useState,
} from 'react';

import {
  Row,
  Modal,
  Form,
  Checkbox,
  DatePicker,
  Slider,
  InputNumber,
  Select,
  Button,
  Table,
  message
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import moment from 'moment';
import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from 'react-csv'

interface Stock {
  tradingfloordate: string,
  tradingcode: number,
  shortname: string,
  closingprice: number,
  financialtraded: number,
  oscillation: number,
  rsi: number,
  band_value: number,
  band_type: string,
  ratio: number
};

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

const Bollinger: FunctionComponent<Props> = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [minRatio, setMinRatio] = useState(-3);
  const [maxRatio, setMaxRatio] = useState(3);
  const [bollingerData, setBollingerData] = useState<Array<Stock>>([]);

  const [ratioState, setRatioState] = useState(false);

  const { latestDate } = props.dates;

  const columns = [
    {
      title: 'Ativo',
      dataIndex: 'tradingcode',
    },
    {
      title: 'Nome',
      dataIndex: 'shortname',
    },
    {
      title: 'Fechamento',
      dataIndex: 'closingprice',
      render: (value: number) => `R$ ${value.toFixed(2)}`.replace(".", ","),
    },
    {
      title: 'Ratio',
      dataIndex: 'ratio',
      sorter: (a: any, b: any) => a.ratio - b.ratio,
    },
    {
      title: 'Vl. Banda',
      dataIndex: 'band_value',
      render: (value: number) => `R$ ${value.toFixed(2)}`.replace(".", ","),
    },
    {
      title: 'Tipo Banda',
      dataIndex: 'band_type',
      filters: [
        {
          text: 'Superior',
          value: 'Superior',
        },
        {
          text: 'Média',
          value: 'Média',
        },
        {
          text: 'Inferior',
          value: 'Inferior',
        },
      ],
      onFilter: (value: any, record: any) => record.band_type.indexOf(value) === 0,
    },
    {
      title: 'RSI',
      dataIndex: 'rsi',
      sorter: (a: any, b: any) => a.rsi - b.rsi,
    },
    {
      title: '%Ult. Osc',
      dataIndex: 'oscillation',
      sorter: (a: any, b: any) => a.oscillation - b.oscillation,
    },
  ];

  const onChangeRatio = (value: any) => {
    setMinRatio(value[0]);
    setMaxRatio(value[1]);
  }

  const onFinish = (values: any) => {
    const date: string = moment(values.date).format('YYYY-MM-DD');
    const volume: number = values.avgvolume;
    const corrected: boolean = values.corrected;
    const distratio: number = values.distratio;
    const window: number = values.b_window || 20;
    const std: number = values.b_std || 2;

    let query_url = `bollinger/?date=${date}&volume=${volume}&window=${window}&std=${std}&corrected=${corrected}`
    if (!ratioState)
      query_url += `&ratio_min=${minRatio}&ratio_max=${maxRatio}`;
    else
      query_url += `&ratio_distance=${distratio}`;

    setLoading(true);
    StockApiProvider.get(query_url)
      .then(r => {
        const { data } = r;

        let tmp_stock = data.filter((stock: any) => {
          const hasBollinger = Object.keys(stock.bollinger).length !== 0;
          return hasBollinger;
        }).map((stock: any) => {
          const bollinger = stock.bollinger;
          const bands: any = { "Upper": bollinger.upper_band, "Average": bollinger.average, "Lower": bollinger.lower_band };
          const labels: any = { "Upper": "Superior", "Average": "Média", "Lower": "Inferior" };

          const obj: Stock = {
            tradingfloordate: stock.tradingfloordate,
            tradingcode: stock.tradingcode,
            shortname: stock.shortname,
            closingprice: stock.closingprice,
            financialtraded: stock.financialtraded,
            oscillation: stock.oscillation,
            rsi: stock.rsi,
            band_value: bands[bollinger.band_type],
            band_type: labels[bollinger.band_type],
            ratio: bollinger.ratio
          }
          return obj;
        });

        if (data.length > 0) message.success('Pesquisa finalizada');
        else message.info('Nenhum dado encontrado');
        setBollingerData([...tmp_stock]);
        setLoading(false);
      });
  };

  return (
    <>
      <Form
        colon={false}
        layout="inline"
        onFinish={onFinish}>
        <Form.Item
          name="date"
          label="Data de corte"
          initialValue={moment(latestDate)}
          rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
          <DatePicker
            format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="avgvolume"
          label="Volume médio acima de"
          initialValue={1000000}
          rules={[{ required: true, message: 'Por favor preencher o volume médio' }]}>
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item
          name="corrected"
          valuePropName="checked"
          initialValue={true}>
          <Checkbox>Preço corrigido</Checkbox>
        </Form.Item>

        <Form.Item label="Intervalo de ratio">
          <Slider
            range
            min={-3}
            max={3}
            step={0.1}
            value={[minRatio, maxRatio]}
            onChange={(value) => onChangeRatio(value)}
            disabled={ratioState}
          />
          <Row>
            <InputNumber
              min={-3}
              max={3}
              step={0.1}
              value={minRatio}
              onMouseEnter={() => setRatioState(false)}
              onChange={(value: any) => setMinRatio(value)}
              disabled={ratioState}
            />
            <InputNumber
              min={-3}
              max={3}
              step={0.1}
              value={maxRatio}
              onMouseEnter={() => setRatioState(false)}
              onChange={(value: any) => setMaxRatio(value)}
              disabled={ratioState}
            />
          </Row>
        </Form.Item>

        <Form.Item
          name="distratio"
          label="Distância do ratio"
          initialValue={maxRatio}>
          <InputNumber
            min={0}
            max={3}
            step={0.1}
            onMouseEnter={() => setRatioState(true)}
            disabled={!ratioState}
          />
        </Form.Item>
        <Form.Item>
          <Button onClick={() => setModalVisible(true)}>
            Editar parâmetros de Bollinger
          </Button>
        </Form.Item>
        <Modal
          centered
          width={300}
          title="Bandas de Bollinger"
          visible={modalVisible}
          footer={[
            <Button key="submit" type="primary" onClick={() => setModalVisible(false)}>
              Confirmar
            </Button>]}>
          <Row justify="center" align="middle">
            <Form.Item name="b_field" label="Campo" initialValue="closingprice" labelAlign="left" style={{ width: '90%' }}>
              <Select style={{ float: 'right', width: 120 }}>
                <Select.Option value="openingprice">Abertura</Select.Option>
                <Select.Option value="closingprice">Fechamento</Select.Option>
                <Select.Option value="maximumprice">Máximo</Select.Option>
                <Select.Option value="minimumprice">Mínimo</Select.Option>
                <Select.Option value="averageprice">Médio</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="b_window" label="Período" initialValue={20} labelAlign="left" style={{ width: '90%' }}>
              <InputNumber min={5} max={50} style={{ float: 'right' }} />
            </Form.Item>
            <Form.Item name="b_std" label="Desvio padrão" initialValue={2} labelAlign="left" style={{ width: '90%' }}>
              <InputNumber min={1.5} max={2.9} style={{ float: 'right' }} />
            </Form.Item>
          </Row>

        </Modal>

        <Form.Item>
          <Button
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary"
            loading={loading}>
            Pesquisar
          </Button>
        </Form.Item>
      </Form>
      <Table
        title={() => (
          <Button
            disabled={bollingerData.length === 0}
            type="primary"
            icon={<DownloadOutlined />}>
            <CSVLink data={bollingerData} filename={"Bollinger.csv"} style={{color: 'inherit'}}>Download</CSVLink>
          </Button>
        )}
        dataSource={bollingerData}
        columns={columns}
        loading={loading} />
    </>
  );
}

export default Bollinger;
