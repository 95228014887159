import React, {
  FunctionComponent,
  useState,
} from 'react';

import {
  Form,
  Checkbox,
  DatePicker,
  Button,
  Table,
  InputNumber,
  Select,
  message,
  Row,
  Col,
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import moment from 'moment';

import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from "react-csv";

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

const AverageCrossing: FunctionComponent<Props> = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [stockData, setStockData] = useState<Array<any>>([]);

  const { latestDate } = props.dates;

  const formItemLayout =
  !dataLoaded
    ? {
        labelCol: { span: 16 },
        wrapperCol: { span: 16 },
      }
    : null;
  
  const commonFilter = [
    { text: 'Maior que 1', value: 'greater'},
    { text: 'Menor que 1', value: 'lower'},
  ]
  const filterFunc = (value: any, record: any, dataIndex: Array<string>) => value === 'greater' ? record[dataIndex[0]][dataIndex[1]] > 1 : record[dataIndex[0]][dataIndex[1]] < 1
  const sorterFunc = (a: any, b: any, dataIndex: Array<string>) => a[dataIndex[0]][dataIndex[1]] - b[dataIndex[0]][dataIndex[1]]

  const columns: any = [
    {
      title: 'Posição',
      render: (text: any, record: any, index: any) => index + 1,
      fixed: 'left',
    },
    {
      title: 'Ativo',
      dataIndex: 'tradingcode',
      fixed: 'left',
    },
    {
      title: 'D0:M1',
      dataIndex: ['D0', 'M1'],
    },
    {
      title: 'D0:M2',
      dataIndex: ['D0', 'M2'],
    },
    {
      title: 'D0:M3',
      dataIndex: ['D0', 'M3'],
    },

    {
      title: 'D-Y:M2/M1',
      dataIndex: ['D0-Y', 'M2/M1'],
      filters: [...commonFilter],
      onFilter: (value: any, record: any) => filterFunc(value, record, ['D0-Y', 'M2/M1']),
      sorter: (a: any, b: any) => sorterFunc(a, b, ['D0-Y', 'M2/M1']),
    },
    {
      title: 'D-X:M2/M1',
      dataIndex: ['D0-X', 'M2/M1'],
      filters: [...commonFilter],
      onFilter: (value: any, record: any) => filterFunc(value, record, ['D0-X', 'M2/M1']),
      sorter: (a: any, b: any) => sorterFunc(a, b, ['D0-X', 'M2/M1']),
    },
    {
      title: 'D0:M2/M1',
      dataIndex: ['D0', 'M2/M1'],
      filters: [...commonFilter],
      onFilter: (value: any, record: any) => filterFunc(value, record, ['D0', 'M2/M1']),
      sorter: (a: any, b: any) => sorterFunc(a, b, ['D0', 'M2/M1']),
    },

    {
      title: 'D-Y:M3/M1',
      dataIndex: ['D0-Y', 'M3/M1'],
      filters: [...commonFilter],
      onFilter: (value: any, record: any) => filterFunc(value, record, ['D0-Y', 'M3/M1']),
      sorter: (a: any, b: any) => sorterFunc(a, b, ['D0-Y', 'M3/M1']),
    },
    {
      title: 'D-X:M3/M1',
      dataIndex: ['D0-X', 'M3/M1'],
      filters: [...commonFilter],
      onFilter: (value: any, record: any) => filterFunc(value, record, ['D0-X', 'M3/M1']),
      sorter: (a: any, b: any) => sorterFunc(a, b, ['D0-X', 'M3/M1']),
    },
    {
      title: 'D0:M3/M1',
      dataIndex: ['D0', 'M3/M1'],
      filters: [...commonFilter],
      onFilter: (value: any, record: any) => filterFunc(value, record, ['D0', 'M3/M1']),
      sorter: (a: any, b: any) => sorterFunc(a, b, ['D0', 'M3/M1']),
    },

    {
      title: 'D-Y:M3/M2',
      dataIndex: ['D0-Y', 'M3/M2'],
      filters: [...commonFilter],
      onFilter: (value: any, record: any) => filterFunc(value, record, ['D0-Y', 'M3/M2']),
      sorter: (a: any, b: any) => sorterFunc(a, b, ['D0-Y', 'M3/M2']),
    },
    {
      title: 'D-X:M3/M2',
      dataIndex: ['D0-X', 'M3/M2'],
      filters: [...commonFilter],
      onFilter: (value: any, record: any) => filterFunc(value, record, ['D0-X', 'M3/M2']),
      sorter: (a: any, b: any) => sorterFunc(a, b, ['D0-X', 'M3/M2']),
    },
    {
      title: 'D0:M3/M2',
      dataIndex: ['D0', 'M3/M2'],
      filters: [...commonFilter],
      onFilter: (value: any, record: any) => filterFunc(value, record, ['D0', 'M3/M2']),
      sorter: (a: any, b: any) => sorterFunc(a, b, ['D0', 'M3/M2']),
    },
    {
      title: 'Ult. Cot.',
      dataIndex: 'closingprice',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Ult. Osc.',
      dataIndex: 'oscillation',
      render: (value: number) => `${value}%`,
    },
  ];

  const onFinish = (values: any) => {
    console.log(values);

    const {
      avg1, avg2, avg3,
      offset1, offset2, offset3,
      x, y,
      date, avgvolume, corrected, field
    } = values;

    const query_url = `
    averagecrossing/?date=${moment(date).format('YYYY-MM-DD')}
    &avg1=${avg1}&offset1=${offset1}
    &avg2=${avg2}&offset2=${offset2}
    &avg3=${avg3}&offset3=${offset3}
    &x=${x}&y=${y}
    &field=${field}
    &volume=${avgvolume}
    &corrected=${corrected}`.replace(/\s\s+/g, '').trim();

    console.log(query_url)

    setLoading(true);
    StockApiProvider.get(query_url)
      .then((r: any) => {
        const { data } = r;
        if (data.length === 0)
          message.info('Nenhum dado encontrado');
        else
          message.success('Pesquisa finalizada');
        setStockData([...data]);
        setLoading(false);
        setDataLoaded(true);
      }).catch(() => {
        message.error('Não foram encontrados cruzamentos de médias')
        setLoading(false);
      });
  };

  return (
    <>
      <Form
        {...formItemLayout}
        onFinish={onFinish}
        layout={dataLoaded ? "inline" : "vertical"}
        style={{maxWidth: 700}}>
        <Form.Item
          name="date"
          label="Data de corte"
          initialValue={moment(latestDate)}
          rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
          <DatePicker
            format="DD/MM/YYYY" />
        </Form.Item>

        {[1, 2, 3].map((value: number) => (
        <Form.Item key={value}>
        <Row gutter={8}>
          <Col span={6}>
          <Form.Item
              name={"avg" + value}
              label={"Dias média " + value}
              initialValue={21}
              rules={[{ required: true, message: 'Por favor preencher dias média' }]}>
              <InputNumber style={{width: '100%'}} min={2} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name={"offset" + value}
              label="Offset"
              initialValue={0}
              rules={[{ required: true, message: 'Por favor preencher o offset' }]}>
              <InputNumber style={{width: '100%'}} min={0} />
            </Form.Item>
          </Col>
        </Row>
        </Form.Item>
        ))}

        <Form.Item>
        <Row gutter={8}>
          <Col span={6}>
          <Form.Item
              name="x"
              label="X"
              initialValue={1}
              rules={[{ required: true, message: 'Por favor preencher dias média' }]}>
              <InputNumber style={{width: '100%'}} min={0} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="y"
              label="Y"
              initialValue={2}
              rules={[{ required: true, message: 'Por favor preencher o offset' }]}>
              <InputNumber style={{width: '100%'}} min={0} />
            </Form.Item>
          </Col>
        </Row>
        </Form.Item>

        <Form.Item
          name="avgvolume"
          label="Volume médio acima de"
          initialValue={1000000}
          rules={[{ required: true, message: 'Por favor preencher o volume médio' }]}>
          <InputNumber min={1000} />
        </Form.Item>

        <Form.Item name="corrected" valuePropName="checked" initialValue={true}>
          <Checkbox>Preço corrigido</Checkbox>
        </Form.Item>

        <Form.Item
          name="field"
          label="Preço"
          initialValue={'closingprice'}
          rules={[{ required: true, message: 'Por favor preencher o campo de preço' }]}>
          <Select style={{ width: 120 }}>
            <Select.Option value="openingprice">Abertura</Select.Option>
            <Select.Option value="closingprice">Fechamento</Select.Option>
            <Select.Option value="minimumprice">Mínimo</Select.Option>
            <Select.Option value="averageprice">Médio</Select.Option>
            <Select.Option value="maximumprice">Máximo</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary">
            Pesquisar
        </Button>
        </Form.Item>
      </Form>
      {(dataLoaded && stockData) &&
      <Table
        title={() => (
          <Button
            disabled={stockData.length === 0}
            type="primary"
            icon={<DownloadOutlined />}>
            <CSVLink data={stockData} filename={"AverageCrossing.csv"} style={{color: 'inherit'}}>Download</CSVLink>
          </Button>
        )}
        loading={loading}
        dataSource={stockData}
        columns={columns}
        scroll={{ x: 1300, y: 500 }}/>
      }
    </>
  );
}

export default AverageCrossing;
