import React, {
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

import {
  Row,
  Col,
  List,
  Skeleton,
  Form,
  Card,
  Checkbox,
  DatePicker,
  Button,
  Tag,
  message
} from 'antd';

import moment from 'moment';

import StockApiProvider from '../../../providers/StockApiProvider';

interface TradingFloor {
  id: number,
  date: string,
  load: boolean | null,
  oscillation: boolean | null,
  correctprices: boolean | null,
  btc: boolean | null,
  freefloat: boolean | null
}

const Importer: FunctionComponent = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [log, setLog] = useState<Array<String>>([]);
  const [latestLoad, setLatestLoad] = useState<TradingFloor>({
    id: 0,
    date: '',
    load: null,
    oscillation: null,
    correctprices: null,
    btc: null,
    freefloat: null
  });

  const onFinish = (values: any) => {
    setLoading(true);
    setLog([]);
    message.info('Iniciando carga de dados');
    const req_obj = {
      date: moment(values.date).format('DDMMYYYY'),
      history: {
        enabled: values.hist_enabled,
        saveCsv: 'values.hist_savecsv',
        keepFile: 'values.hist_keepfile'
      },
      oscillation: {
        enabled: values.osc_enabled,
      },
      correction: {
        enabled: values.corr_enabled,
        type: 'values.corr_type'
      }
    }
    StockApiProvider.post('importer/', req_obj)
    .then(r => {
      const { data } = r;
      let tmp_log = data.log.split('\n').map((e: string) => e);
      setLog([...tmp_log]);
      setLoading(false);
      message.success('Processo finalizado. Verificar log para detalhes.');
    })
    .catch(err => setLoading(false));
  };

  useEffect(() => {
    StockApiProvider.get('tradingfloor/latest/')
    .then(res => {
      const { data } = res;
      setLatestLoad({...data});
    })
  }, []);

  return (
    <Row>
      <Col span={12}>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          style={{ maxWidth: 300 }}
          layout='vertical'>

          <Form.Item
            name="date"
            label="Data da carga"
            initialValue={moment()}>
            <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="hist_enabled"
            valuePropName="checked"
            initialValue={true}>
            <Checkbox>Carga de dados</Checkbox>
          </Form.Item>

          <Form.Item
            name="osc_enabled"
            valuePropName="checked"
            initialValue={true}>
            <Checkbox>Carga de oscilação</Checkbox>
          </Form.Item>

          <Form.Item
            name="corr_enabled"
            valuePropName="checked"
            initialValue={true}>
            <Checkbox>Correção de preços</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              htmlType="submit"
              type="primary"
              style={{width: '100%'}}>
              Realizar carga de dados
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col span={12}>
        <List
        header={<div>LOG</div>}
        bordered
        dataSource={log}
        renderItem={item => (
          <List.Item>
            {item}
          </List.Item>
        )}>
          {loading &&
          <List.Item>
            <Skeleton active />
          </List.Item>}
        </List>
        <Card
          title="Dados da última carga"
          style={{marginTop: 16}}
          extra={<p>{moment(latestLoad.date).format('DD/MM/YYYY')}</p>}>
          <Tag color={latestLoad.load ? "green" : "red"}>Carga de dados</Tag>
          <Tag color={latestLoad.oscillation ? "green" : "red"}>Oscilação</Tag>
          <Tag color={latestLoad.btc ? "green" : "red"}>BTC</Tag>
          <Tag color={latestLoad.freefloat ? "green" : "red"}>Free float</Tag>
          <Tag color={latestLoad.correctprices ? "green" : "red"}>Correção de preços</Tag>
        </Card>
      </Col>
    </Row>
  );
}

export default Importer;
