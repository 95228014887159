import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import {
  Form,
  Tag,
  Checkbox,
  DatePicker,
  Select,
  Button,
  Table,
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import moment from 'moment';

import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from "react-csv";


interface Option {
  value: string,
  label: ReactElement | string
};

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

const CorporateEvents: FunctionComponent<Props> = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const [assetData, setAssetData] = useState<Array<Option>>([]);


  const [stockData, setStockData] = useState<Array<any>>([]);

  const { firstDate, latestDate } = props.dates;

  useEffect(() => {
    StockApiProvider.get('assets/')
      .then((r: any) => {
        const { data } = r;
        const empty = { tradingcode: "", shortname: "Todos" }
        const n_data = [empty, ...data]
        const tmp_options: Option[] = n_data.map((asset: any) => ({
          value: asset.tradingcode,
          label: (
            <>
              <Tag>{asset.tradingcode}</Tag>
              <span>{asset.shortname}</span>
            </>
          )
        }));
        setAssetData([...tmp_options]);
      });
  }, []);

  const columns: any = [
    {
      title: 'Ativo',
      dataIndex: 'tradingcode',
    },
    {
      title: 'Data Com',
      dataIndex: 'date_com',
      render: (date: string) => date.split('-').reverse().join('/'),
      sorter: (a: any, b: any) => new Date(a.date_com).getTime() - new Date(b.date_com).getTime(),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Data EX',
      dataIndex: 'date_ex',
      render: (date: string) => date.split('-').reverse().join('/'),
    },
    {
      title: 'Fech.',
      dataIndex: 'closingprice',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Osc. Real',
      dataIndex: 'real_osc',
      render: (value: number) => `${value}%`,
    },
    {
      title: 'Fech.Ant.Esp',
      dataIndex: 'prev_expected_closing',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Fech.Ant.Real',
      dataIndex: 'prev_real_closing',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: '% Evento',
      dataIndex: 'event_percent',
      render: (value: number) => `${value}%`,
    },
    {
      title: 'Vl.Evento',
      dataIndex: 'event_value',
    },
    {
      title: 'Fator',
      dataIndex: 'correction_factor',
    },
    {
      title: 'Fator AC',
      dataIndex: 'ac_correction_factor',
    },
    {
      title: 'Fech.Ant.Corr',
      dataIndex: 'ap_closing_corrected',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Fech.Ant.DB',
      dataIndex: 'db_closing_corrected',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Diferença',
      dataIndex: 'price_diff',
      render: (value: number) => value.toLocaleString('pt-BR'),
    },
  ];

  const onFinish = (values: any) => {

    const start_date: string = moment(values.daterange[0]._d).format('YYYY-MM-DD');
    const end_date: string = moment(values.daterange[1]._d).format('YYYY-MM-DD');
    const event_only: boolean = values.event_only;
    const diff_only: boolean = values.diff_only;
    const tradingcode: string = values.tradingcode;

    let query_url: string = `corporateevents/?start=${start_date}&end=${end_date}&event_only=${event_only}&diff_only=${diff_only}`;
    if (tradingcode !== "")
      query_url += `&tradingcode=${tradingcode}`;

    setLoading(true);
    StockApiProvider.get(query_url)
      .then((r: any) => {
        const { data } = r;
        setStockData([...data]);
        setLoading(false);
      }).catch(() => setLoading(false));
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        layout="inline"
        style={{ height: '60px' }}>
        <Form.Item
          name="daterange"
          initialValue={[moment(firstDate), moment(latestDate)]}
          rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
          <DatePicker.RangePicker
            ranges={{
              'Hoje': [moment(), moment()],
              'Mês atual': [moment().startOf('month'), moment().endOf('month')],
              'Ano atual': [moment().startOf('year'), moment().endOf('year')],
            }}
            format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="tradingcode"
          initialValue="">
          <Select
            showSearch
            showArrow={false}
            style={{ width: 250 }}
            placeholder="Código ativo"
            optionFilterProp="value"
            filterOption={(input, option: any) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {assetData.map((option: Option) => {
              return <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
            })}
          </Select>
        </Form.Item>

        <Form.Item name="event_only" valuePropName="checked" initialValue={false}>
          <Checkbox>Somente datas com Evento</Checkbox>
        </Form.Item>
        <Form.Item name="diff_only" valuePropName="checked" initialValue={false}>
          <Checkbox>Somente datas com Diferença</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary">
            Pesquisar
        </Button>
        </Form.Item>
      </Form>

      <Table
        title={() => (
          <Button
            disabled={stockData.length === 0}
            type="primary"
            icon={<DownloadOutlined />}>
            <CSVLink data={stockData} filename={"CorporateEvents.csv"} style={{color: 'inherit'}}>Download</CSVLink>
          </Button>
        )}
        loading={loading}
        dataSource={stockData}
        columns={columns} />
    </>
  );
}

export default CorporateEvents;
