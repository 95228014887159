import React, {
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

import {
  Space,
  Typography,
  Form,
  Table,
  Descriptions,
  Select,
  DatePicker,
  Checkbox,
  Button,
  TreeSelect,
  Switch,
  Input,
  InputNumber,
  message,
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from "react-csv";
import moment from 'moment';

interface TreeNodeData {
  title: string | React.ReactNode,
  value: string,
  children: Array<TreeNodeData>
};

interface StockData {
  meta: {
    wallet: number,
    oscillation: number,
    trades: number,
  },
  data: Array<any>,
}

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

const RentabilitySim: FunctionComponent<Props> = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [nodeData, setNodeData] = useState<Array<any>>([]);
  const [stockData, setStockData] = useState<StockData>({
    meta: { wallet: 0, oscillation: 0, trades: 0, }, data: []
  });
  const [assetsList, setAssetsList] = useState(true);

  const [mainForm] = Form.useForm();

  const { firstDate, latestDate } = props.dates;

  const formItemLayout =
  !dataLoaded
    ? {
        labelCol: { span: 16 },
        wrapperCol: { span: 16 },
      }
    : null;

  const columns: any = [
    {
      title: 'N',
      render: (text: any, record: any, index: any) => index + 1,
      fixed: 'left',
    },
    {
      title: 'Ativo',
      dataIndex: 'tradingcode',
      fixed: 'left',
    },
    {
      title: '% Osc. Final',
      dataIndex: 'osc_final',
      render: (value: number) => `${value} %`,
    },
    {
      title: '% Osc. Máx.',
      dataIndex: 'osc_max',
      render: (value: number) => `${value} %`,
    },
    {
      title: 'Data Máx.',
      dataIndex: 'osc_max_date',
      render: (value: string) => moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      title: '% Osc. Min.',
      dataIndex: 'osc_min',
      render: (value: number) => `${value} %`,
    },
    {
      title: 'Data Min.',
      dataIndex: 'osc_min_date',
      render: (value: string) => moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    },
    {
      title: 'Mín',
      dataIndex: 'price_min',
      render: (value: number) => value ? value.toLocaleString('pt-BR') : '',
    },
    {
      title: 'Máx',
      dataIndex: 'price_max',
      render: (value: number) => value ? value.toLocaleString('pt-BR') : '',
    },
    {
      title: 'Últ.',
      dataIndex: 'price_last',
      render: (value: number) => value ? value.toLocaleString('pt-BR') : '',
    },
  ];

  useEffect(() => {
    // Get asset list
    StockApiProvider.get('class/listasativos_/')
      .then(({ data }) => {
        setNodeData([...data]);
      })
      .catch(() => { });
  }, []);

  const makeTreeData = (unprocessed_data: Array<any>): Array<TreeNodeData> => {

    let tree: Array<TreeNodeData> = [];

    for (let i = 0; i < unprocessed_data.length; i++) {
      const node = unprocessed_data[i];
      tree.push({
        title: node.value ? `${node.name}: ${node.value}` : `${node.name}`,
        value: node.id,
        children: makeTreeData(node.children)
      })
    }

    return tree;
  };

  const onFinish = (values: any) => {
    console.log(values);

    const {
      corrected, input_assets, field,
    } = values;

    const start_date: string = moment(values.daterange[0]._d).format('YYYY-MM-DD');
    const end_date: string = moment(values.daterange[1]._d).format('YYYY-MM-DD');

    let query_url = `
    rentabilitysim/
    ?start_date=${start_date}
    &end_date=${end_date}
    &corrected=${corrected}
    &field=${field}
    &input_assets=${input_assets}
    &input_assets_list=${assetsList}`.replace(/\s\s+/g, '').trim();

    setLoading(true);
    StockApiProvider.get(query_url)
      .then((r: any) => {
        const { data } = r;
        if (data.length === 0)
          message.info('Nenhum dado encontrado');
        else
          message.success('Pesquisa finalizada');
        console.log(data);
        setStockData({...data});
        setLoading(false);
        setDataLoaded(true);
      }).catch(() => {
        message.error('Houve um erro desconhecido')
        setLoading(false)
      });
  };

  // const handleSave = (row: any) => {
  //   const newData = [...stockData];
  //   const index = newData.findIndex(item => row.key === item.key);
  //   const item = newData[index];
  //   newData.splice(index, 1, {
  //     ...item,
  //     ...row,
  //   });
  //   setStockData([...newData]);
  // };

  return (
    <>
    <Form
      {...formItemLayout}
      form={mainForm}
      onFinish={onFinish}
      layout={"vertical"}
      style={{maxWidth: 700}}>

      <Form.Item
        name="daterange"
        initialValue={[0, moment(latestDate)]}
        rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
        <DatePicker.RangePicker
          ranges={{
            'Hoje': [moment(), moment()],
            'Mês atual': [moment().startOf('month'), moment().endOf('month')],
            'Ano atual': [moment().startOf('year'), moment().endOf('year')],
          }}
          format="DD/MM/YYYY" />
      </Form.Item>

      <Form.Item
        name="walet"
        label="Valor da carteira"
        initialValue={1000000}
        rules={[{ required: true }]}>
        <InputNumber 
          min={0}
          step={0.01}
          formatter={value => `R$ ${value}`}
          parser={(value: any) => value.replace('R$ ', '')}/>
      </Form.Item>

      {/* Lista de ativos */}
      <Space>
        <Typography.Text>
          Lista de ativos
        </Typography.Text>
        <Switch
          checkedChildren="Definida"
          unCheckedChildren="Escrita"
          checked={assetsList}
          onChange={(checked: boolean) => setAssetsList(checked)}/>
      </Space>
      
      <Form.Item
        name="input_assets"
        rules={[{ required: true, message: 'Por favor selecionar a lista de ativos' }]}
        style={{ marginTop: 10 }}>
        {assetsList ?
        <TreeSelect
          style={{ minWidth: 250, width: '100%' }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeData={makeTreeData(nodeData)}
          placeholder="Selecionar lista"
        />
        :
        <Input placeholder="Ativos separados por vírgula" />
        }
      </Form.Item>

      <Form.Item
        name="field"
        label="Preço"
        initialValue={'closingprice'}
        rules={[{ required: true, message: 'Por favor preencher o campo de preço' }]}>
        <Select style={{ width: 120 }}>
          <Select.Option value="openingprice">Abertura</Select.Option>
          <Select.Option value="closingprice">Fechamento</Select.Option>
          <Select.Option value="minimumprice">Mínimo</Select.Option>
          <Select.Option value="averageprice">Médio</Select.Option>
          <Select.Option value="maximumprice">Máximo</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="corrected" valuePropName="checked" initialValue={true}>
        <Checkbox>Preço corrigido</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          loading={loading}
          htmlType="submit"
          icon={<SearchOutlined />}
          type="primary">
          Pesquisar
        </Button>
      </Form.Item>
    </Form>
    
    {(dataLoaded && stockData.data) &&
    <>
      <Descriptions bordered>
        <Descriptions.Item label="Valor da carteira" span={3}>{stockData.meta.wallet?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Descriptions.Item>
        <Descriptions.Item label="Oscilação" span={3}>{`${stockData.meta.oscillation} %`}</Descriptions.Item>
        <Descriptions.Item label="Pregões" span={3}>{stockData.meta.trades}</Descriptions.Item>
      </Descriptions>
      <Table
        title={() => (
          <Button
            disabled={stockData.data.length === 0}
            type="primary"
            icon={<DownloadOutlined />}>
            <CSVLink separator={";"} data={stockData.data} filename={"RentabilitySimulation.csv"} style={{color: 'inherit'}}>Download</CSVLink>
          </Button>
        )}
        loading={loading}
        dataSource={stockData.data}
        columns={columns}
        scroll={{ x: 1300, y: 500 }}/>
    </>
    }
    </>
  );
}

export default RentabilitySim;
