import React, {
  FunctionComponent,
  useState,
} from 'react';

import {
  Form,
  Checkbox,
  DatePicker,
  Button,
  Table,
  InputNumber,
  message
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import moment from 'moment';

import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from "react-csv";

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

const BTCAnalysis: FunctionComponent<Props> = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const [stockData, setStockData] = useState<Array<any>>([]);

  const { latestDate } = props.dates;

  // Headers for React-CSV
  const headers = [
    { label: 'Trading Code', key: 'tradingcode' },
    { label: 'Qtd Alugado', key: 'quantitytradedbtc' },
    { label: '% Free Float', key: 'free_float_percent' },
    { label: 'Volume Alugado', key: 'financialtradedbtc' },
    { label: 'Volume Médio', key: 'avg_volume' },
    { label: '% Var. Vol', key: 'volume_variation' },
    { label: 'PMed. Alugado', key: 'lending_avg' },
    { label: 'Fechamento', key: 'closingprice' },
    { label: '% Fech/PMed', key: 'percent_closing_to_lending' },
    { label: 'Ult. Osc', key: 'oscillation' },
  ];

  const columns: any = [
    {
      title: 'Posição',
      render: (text: any, record: any, index: any) => index + 1,
      fixed: 'left',
    },
    {
      title: 'Ativo',
      dataIndex: 'tradingcode',
      fixed: 'left',
    },
    {
      title: 'Qtd Alugado',
      dataIndex: 'quantitytradedbtc',
      render: (value: number) => value.toLocaleString('pt-BR'),
    },
    {
      title: '% Free Float',
      dataIndex: 'free_float_percent',
      render: (value: number) => `${value}%`,
      sorter: (a: any, b: any) => a.free_float_percent - b.free_float_percent,
    },
    {
      title: 'Volume Alugado',
      dataIndex: 'financialtradedbtc',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      sorter: (a: any, b: any) => a.financialtradedbtc - b.financialtradedbtc,
    },
    {
      title: 'Volume Médio',
      dataIndex: 'avg_volume',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: '% Var. Vol',
      dataIndex: 'volume_variation',
      render: (value: number) => `${value}%`,
      sorter: (a: any, b: any) => a.volume_variation - b.volume_variation,
    },
    {
      title: 'PMed. Alugado',
      dataIndex: 'lending_avg',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Fechamento',
      dataIndex: 'closingprice',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: '% Fech/PMed',
      dataIndex: 'percent_closing_to_lending',
      render: (value: number) => `${value}%`,
      sorter: (a: any, b: any) => a.percent_closing_to_lending - b.percent_closing_to_lending,
    },
    {
      title: 'Ult. Osc',
      dataIndex: 'oscillation',
      render: (value: number) => `${value}%`,
    },
  ];

  const onFinish = (values: any) => {
    const {
      date,
      avgvolume,
      corrected,
      totalassets,
    } = values;

    const date_cnv = moment(date).format('YYYY-MM-DD');

    setLoading(true);
    StockApiProvider.get(`btcanalysis/?date=${date_cnv}&trades=${totalassets}&volume=${avgvolume}&corrected=${corrected}`)
      .then((r: any) => {
        const { data } = r;
        if (data.length === 0)
          message.info('Nenhum dado encontrado');
        else
          message.success('Pesquisa finalizada');
        console.log(data);
        setStockData([...data]);
        setLoading(false);
      }).catch(() => setLoading(false));
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        layout="inline"
        style={{ height: '60px' }}>
        <Form.Item
          name="date"
          label="Data de corte"
          initialValue={moment(latestDate)}
          rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
          <DatePicker
            format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="totalassets"
          label="Qtd Pregões"
          initialValue={21}
          rules={[{ required: true, message: 'Por favor preencher o total de pregões' }]}>
          <InputNumber min={2} />
        </Form.Item>

        <Form.Item
          name="avgvolume"
          label="Volume médio acima de"
          initialValue={1000000}
          rules={[{ required: true, message: 'Por favor preencher o volume médio' }]}>
          <InputNumber min={1000} />
        </Form.Item>

        <Form.Item name="corrected" valuePropName="checked" initialValue={true}>
          <Checkbox>Preço corrigido</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary">
            Pesquisar
        </Button>
        </Form.Item>
      </Form>

      <Table
        title={() => (
          <Button
            disabled={stockData.length === 0}
            type="primary"
            icon={<DownloadOutlined />}>
            <CSVLink data={stockData} headers={headers} filename={"BTCAnalysis.csv"} style={{color: 'inherit'}}>Download</CSVLink>
          </Button>
        )}
        loading={loading}
        dataSource={stockData}
        columns={columns}
        scroll={{ x: 1300, y: 500 }}/>
    </>
  );
}

export default BTCAnalysis;
