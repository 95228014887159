import React, { FunctionComponent } from 'react';
import { useHistory } from "react-router-dom";

import {
  Form,
  Input,
  Button,
  Row,
  Card,
  message,
} from 'antd';
import {
  MailOutlined,
  LockOutlined,
} from '@ant-design/icons';

const Login: FunctionComponent = () => {
  let history = useHistory();

  const onFinish = (values: any) => {
    if (values.email === 'admin' && values.password === 'admin') {
      history.push("/panel")
    } else {
      message.error('Dados inválidos');
    }
  };

  return (
    <Row
      align="middle"
      justify="center"
      style={{ minHeight: '100vh' }}>
      <Card title="IASTOCK" hoverable={true}>
        <Form
          name="iastock_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          style={{ width: '400px' }}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Email inválido' }]}>
            <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Senha inválida' }]}>
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Senha" />
          </Form.Item>
          <Form.Item>
            <a className="login-form-forgot" href="/panel">Recuperar senha</a>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Entrar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Row>
  );
}

export default Login
