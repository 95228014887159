import React, {
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

import {
  Row,
  Col,
  Space,
  Modal,
  Typography,
  Form,
  Table,
  Button,
  DatePicker,
  TreeSelect,
  Checkbox,
  Radio,
  Upload,
  Switch,
  Input,
  InputNumber,
  message,
  Select,
  Divider,
} from 'antd';
import {
  SearchOutlined,
  DownloadOutlined,
  PlusOutlined,
  CloudUploadOutlined,
  SaveOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import EditableTable from '../../EditableTable';

import moment from 'moment';

import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from "react-csv";

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

interface TreeNodeData {
  title: string | React.ReactNode,
  value: string,
  children: Array<TreeNodeData>
};

const AssetFilter: FunctionComponent<Props> = (props: Props) => {

  const { latestDate } = props.dates;
  const priceFilterObj = {
    "key": 0,
    "order": 0,
    "type1": "openingprice",
    "date1": 0,
    "op": ">",
    "factor": 1.0000,
    "type2": "openingprice",
    "date2": 0
  };
  const volumeFilterObj = {
    "key": 0,
    "order": 0,
    "preg1": 1,
    "date1": 0,
    "op": ">",
    "factor": 1.0000,
    "preg2": 1,
    "date2": 0
  };
  const ratioFilterObj = {
    "key": 0,
    "order": 0,
    "preg1": 1,
    "date1": 0,
    "op": ">",
    "factor": 1.0000,
    "preg2": 1,
    "date2": 0
  };

  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [nodeData, setNodeData] = useState<Array<any>>([]);
  const [filterParamData, setFilterParamData] = useState<Array<any>>([]);
  const [stockData, setStockData] = useState<Array<any>>([]);
  const [assetList, setAssetList] = useState(true);

  const [priceData, setPriceData] = useState<Array<any>>([priceFilterObj]);
  const [volumeData, setVolumeData] = useState<Array<any>>([volumeFilterObj]);
  const [ratioData, setRatioData] = useState<Array<any>>([ratioFilterObj]);

  const [loadModalVisible, setLoadModalVisible] = React.useState(false);
  const [saveModalVisible, setSaveModalVisible] = React.useState(false);
  const [modalChoice, setModalChoice] = React.useState('list');
  const [modalLoading, setModalLoading] = React.useState(false);

  const [mainForm] = Form.useForm();
  const [priceForm] = Form.useForm();
  const [volumeForm] = Form.useForm();
  const [ratioForm] = Form.useForm();

  const formItemLayout =
  !true
    ? {
        labelCol: { span: 16 },
        wrapperCol: { span: 16 },
      }
    : null;
  
  const maxPriceFilters = 7;
  const maxVolumeFilters = 3;
  const maxRatioFilters = 3;
  const totalMaxFilters = maxPriceFilters + maxVolumeFilters + maxRatioFilters;

  const addPriceFilter = () => {
    let newFilters = [...priceData];
    newFilters.push({...priceFilterObj, "key": priceData.length});
    setPriceData([...newFilters]);
  };

  const addVolumeFilter = () => {
    let newFilters = [...volumeData];
    newFilters.push({...volumeFilterObj, "key": volumeData.length});
    setVolumeData([...newFilters]);
  };

  const addRatioFilter = () => {
    let newFilters = [...ratioData];
    newFilters.push({...ratioFilterObj, "key": ratioData.length});
    setRatioData([...newFilters]);
  };

  const prices = [
    {
      "value": "openingprice",
      "label": "Abertura",
    },
    {
      "value": "closingprice",
      "label": "Fechamento",
    },
    {
      "value": "minimumprice",
      "label": "Mínimo",
    },
    {
      "value": "averageprice",
      "label": "Médio",
    },
    {
      "value": "maximumprice",
      "label": "Máximo",
    },
  ];
  const operators = [">", "<", ">=", "<=", "=", "#"];

  const priceSelect = (
    <Select>
      {prices.map((price: any) => (
        <Select.Option key={price.value} value={price.value}>
          {price.label}
        </Select.Option>
      ))}
    </Select>
  );
  const operatorSelect = (
    <Select>
      {operators.map((op: any) => (
        <Select.Option key={op} value={op}>
          {op}
        </Select.Option>
      ))}
    </Select>
  );

  const priceColumns: any = [
    {
      title: 'Ordem',
      dataIndex: 'order',
      editable: true,
      inputNode: <InputNumber min={0} max={totalMaxFilters} />,
      inputType: 'text',
    },
    {
      title: 'T PRE 1',
      dataIndex: 'type1',
      editable: true,
      inputNode: priceSelect,
      render: (value: string) => prices.find(p => p.value === value)?.label,
    },
    {
      title: 'D PRE 1',
      dataIndex: 'date1',
      editable: true,
      inputNode: <InputNumber max={0} />,
    },
    {
      title: 'OP',
      dataIndex: 'op',
      editable: true,
      inputNode: operatorSelect,
    },
    {
      title: 'MULT',
      dataIndex: 'factor',
      editable: true,
      inputNode: <InputNumber step={0.0001}/>,
    },
    {
      title: 'T PRE 2',
      dataIndex: 'type2',
      editable: true,
      inputNode: priceSelect,
      render: (value: string) => prices.find(p => p.value === value)?.label,
    },
    {
      title: 'D PRE 2',
      dataIndex: 'date2',
      editable: true,
      inputNode: <InputNumber max={0} />,
    },
  ];

  const volumeColumns: any = [
    {
      title: 'Ordem',
      dataIndex: 'order',
      editable: true,
      inputNode: <InputNumber min={0} max={totalMaxFilters} />,
      inputType: 'text',
    },
    {
      title: 'N PREG 1',
      dataIndex: 'preg1',
      editable: true,
      inputNode: <InputNumber />,
    },
    {
      title: 'D VOL 1',
      dataIndex: 'date1',
      editable: true,
      inputNode: <InputNumber max={0} />,
    },
    {
      title: 'OP',
      dataIndex: 'op',
      editable: true,
      inputNode: operatorSelect,
    },
    {
      title: 'MULT',
      dataIndex: 'factor',
      editable: true,
      inputNode: <InputNumber step={0.0001}/>,
    },
    {
      title: 'N PREG 2',
      dataIndex: 'preg2',
      editable: true,
      inputNode: <InputNumber />,
    },
    {
      title: 'D VOL 2',
      dataIndex: 'date2',
      editable: true,
      inputNode: <InputNumber max={0} />,
    },
  ];

  const ratioColumns: any = [
    {
      title: 'Ordem',
      dataIndex: 'order',
      editable: true,
      inputNode: <InputNumber min={0} max={totalMaxFilters} />,
      inputType: 'text',
    },
    {
      title: 'N PREG 1',
      dataIndex: 'preg1',
      editable: true,
      inputNode: <InputNumber />,
    },
    {
      title: 'D VOL/N 1',
      dataIndex: 'date1',
      editable: true,
      inputNode: <InputNumber max={0} />,
    },
    {
      title: 'OP',
      dataIndex: 'op',
      editable: true,
      inputNode: operatorSelect,
    },
    {
      title: 'MULT',
      dataIndex: 'factor',
      editable: true,
      inputNode: <InputNumber step={0.0001}/>,
    },
    {
      title: 'N PREG 2',
      dataIndex: 'preg2',
      editable: true,
      inputNode: <InputNumber />,
    },
    {
      title: 'D VOL/N 2',
      dataIndex: 'date2',
      editable: true,
      inputNode: <InputNumber max={0} />,
    },
  ];

  const resultColumns: any = [
    {
      title: 'ORDEN',
      dataIndex: 'order',
    },
    {
      title: 'UNIV',
      dataIndex: 'len_in',
    },
    {
      title: 'SELEC',
      dataIndex: 'len_out',
    },
    {
      title: '%',
      dataIndex: 'passed',
    },
    {
      title: 'ATIVOS',
      dataIndex: 'out',
      render: (value: Array<string>) => value.join(", "),
    },
  ];

  const fetchParams = () => {
    StockApiProvider.get(`class/filter_params/`)
      .then((r: any) => {
        console.log(r.data);
        setFilterParamData([...r.data]);
      });
  };

  useEffect(() => {
    // Get filters params
    fetchParams();
    // Get asset list
    StockApiProvider.get('class/listasativos_/')
      .then(({ data }) => {
        setNodeData([...data]);
      })
      .catch(() => { });
  }, []);

  const makeTreeData = (unprocessed_data: Array<any>): Array<TreeNodeData> => {

    let tree: Array<TreeNodeData> = [];

    for (let i = 0; i < unprocessed_data.length; i++) {
      const node = unprocessed_data[i];
      tree.push({
        title: `${node.name}`,
        value: node.id,
        children: makeTreeData(node.children)
      })
    }

    return tree;
  };

  const onFinish = (values: any) => {

    const {
      date, avgvolume, asset_list, corrected
    } = values;

    const postObj = {
      "cutdate": moment(date).format('YYYY-MM-DD'),
      "volume": avgvolume,
      "corrected": corrected,
      "using_list": assetList,
      "asset_list": asset_list,
      "price_filters": priceData,
      "volume_filters": volumeData,
      "ratio_filters": ratioData,
    };

    console.log(postObj);

    setLoading(true);
    StockApiProvider.post('/assetfilter/', postObj).then((r: any) => {
      const { data } = r;
      if (data.length === 0)
        message.info('Nenhum dado encontrado');
      else
        message.success('Pesquisa finalizada');
      console.log(data);
      setStockData([...data]);
      setLoading(false);
      setDataLoaded(true);
    }).catch(() => {
      message.error('Houve um erro no processamento dos filtros')
      setLoading(false)
    });

  };

  const setFiltersFromJson = (json: string) => {
    const resultObj = JSON.parse(json);
    const price_filters = resultObj['price_filters'].map((f: any, index: number) => ({ ...f, key: index }));
    const volume_filters = resultObj['volume_filters'].map((f: any, index: number) => ({ ...f, key: index }));
    const ratio_filters = resultObj['ratio_filters'].map((f: any, index: number) => ({ ...f, key: index }));
    setPriceData([...price_filters]);
    setVolumeData([...volume_filters]);
    setRatioData([...ratio_filters]);
  };

  const onUploadFile = (file: File) => {
    try {
      const fileReader = new FileReader();
      fileReader.readAsText(file, "UTF-8");
      fileReader.onload = e => {
        const resultJson = String(e.target?.result);
        setFiltersFromJson(resultJson);
        return true;
      };
    } catch {
      return false;
    }
  };

  const onReadList = async (value: string) => {
    try {
      const response = await StockApiProvider.get(`/class/${value}/`);
      const { data } = response;
      setFiltersFromJson(data[0].value);
    } catch {
      return false;
    }
  };

  const mapFilter = (filterArr: Array<any>) => {
    return filterArr.map(f => {
      let newF = JSON.parse(JSON.stringify(f));
      delete newF['key'];
      return newF;
    });
  };

  const onSaveList = async (values: any) => {
    const { id, name } = values;
    setModalLoading(true);

    const json = JSON.stringify({
      "price_filters": mapFilter(priceData),
      "volume_filters": mapFilter(volumeData),
      "ratio_filters": mapFilter(ratioData),
    });

    const res = await StockApiProvider.post('class/', {
      id: `filter_params_${id}`,
      name,
      value: json,
      parent: 'filter_params'
    })
    if (res.status == 200 || res.status == 201) {
      message.success('Parâmetros salvos');
      fetchParams();
    } else {
      message.error('Houve um erro ao enviar os dados')
    };
    setModalLoading(false);
  };

  const onSaveJson = () => {
    const fileData = JSON.stringify({
      "price_filters": mapFilter(priceData),
      "volume_filters": mapFilter(volumeData),
      "ratio_filters": mapFilter(ratioData),
    }, null, 4);
    const blob = new Blob([fileData], {type: "text/plain"});
    const url = URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.download = 'params.json';
    link.href = url;
    link.click();
    // var data = new Blob([res], {type: 'text/csv'});
    // var csvURL = window.URL.createObjectURL(data);
    // tempLink = document.createElement('a');
    // tempLink.href = csvURL;
    // tempLink.setAttribute('download', 'filename.csv');
    // tempLink.click();
  }

  return (
    <Row>
      <Modal
        title="Carregar parâmetros"
        visible={loadModalVisible}
        onOk={() => setLoadModalVisible(false)}
        onCancel={() => setLoadModalVisible(false)}
        confirmLoading={modalLoading}
        footer={
          <Button key="submit" type="primary" onClick={() => setLoadModalVisible(false)}>
            OK
          </Button>
        }>
        <Radio.Group onChange={(e) => setModalChoice(e.target.value)} value={modalChoice}>
          <Radio value="list">Lista</Radio>
          <Radio value="computer">Computador</Radio>
        </Radio.Group>
        <Divider />
        {modalChoice === "list"
        ? (
          <TreeSelect
            style={{ minWidth: 250, width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={makeTreeData(filterParamData)}
            placeholder="Selecionar lista"
            onChange={onReadList}
          />
        )
        : (
          <Upload
            customRequest={(d) => onUploadFile(d.file)}
            multiple={false}
            showUploadList={false}>
            <Button icon={<UploadOutlined />}>Carregar arquivo</Button>
          </Upload>
        )
        }
      </Modal>

      <Modal
        title="Salvar parâmetros"
        visible={saveModalVisible}
        onOk={() => setSaveModalVisible(false)}
        onCancel={() => setSaveModalVisible(false)}
        confirmLoading={modalLoading}
        footer={
          <Button key="submit" type="primary" onClick={() => setSaveModalVisible(false)}>
            OK
          </Button>
        }>
        <Radio.Group onChange={(e) => setModalChoice(e.target.value)} value={modalChoice}>
          <Radio value="list">Lista</Radio>
          <Radio value="computer">Computador</Radio>
        </Radio.Group>
        <Divider />
        {modalChoice === "list"
        ? (
          <Form onFinish={onSaveList}>
            <Form.Item name="id" rules={[{ required: true, message: 'ID inválido' }]}>
              <Input placeholder="id" />
            </Form.Item>
            <Form.Item name="name" rules={[{ required: true, message: 'Nome inválido' }]}>
              <Input placeholder="Nome" />
            </Form.Item>
            <Form.Item>
            <Button
              loading={modalLoading}
              htmlType="submit"
              icon={<SaveOutlined />}
              type="primary">
              Salvar
            </Button>
          </Form.Item>
          </Form>
        )
        : (
          <Button
            icon={<DownloadOutlined />}
            onClick={onSaveJson}>
              Baixar arquivo
          </Button>
        )
        }
      </Modal>

      <Row>
      <Form
        {...formItemLayout}
        form={mainForm}
        onFinish={onFinish}
        layout={"inline"}
        style={{alignItems: 'center',}}>

        <Form.Item
          name="date"
          label="Data de corte"
          initialValue={moment(latestDate)}
          rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
          <DatePicker
            format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="avgvolume"
          label="Volume médio acima de"
          initialValue={1000000}
          rules={[{ required: true, message: 'Por favor preencher o volume médio' }]}>
          <InputNumber min={1000} />
        </Form.Item>

        <Form.Item name="corrected" valuePropName="checked" initialValue={true}>
          <Checkbox>Preço corrigido</Checkbox>
        </Form.Item>

        {/* Lista de ativos */}
        <Space>
          <Typography.Text>
            Lista de ativos
          </Typography.Text>
          <Switch
            checkedChildren="Definida"
            unCheckedChildren="Escrita"
            checked={assetList}
            onChange={(checked: boolean) => setAssetList(checked)}/>
          <Form.Item
            name="asset_list"
            rules={[{ required: true, message: 'Por favor selecionar a lista de ativos' }]}
            style={{ marginTop: 10 }}>

            {assetList ?
            <TreeSelect
              style={{ minWidth: 250, width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={makeTreeData(nodeData)}
              placeholder="Selecionar lista"
            />
            :
            <Input placeholder="Ativos separados por vírgula" />
            }
          </Form.Item>
        </Space>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary">
            Calcular
          </Button>
        </Form.Item>
      </Form>

      <Divider />
      </Row>
      <Row gutter={16}>
        <Col span={24}>
        <Table
          title={() => (
            <Space>
              <Button
                type="primary"
                icon={<CloudUploadOutlined />}
                onClick={() => setLoadModalVisible(true)}>
                Carregar
              </Button>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => setSaveModalVisible(true)}>
                Salvar
              </Button>
              <Button
                disabled={stockData.length === 0}
                type="primary"
                icon={<DownloadOutlined />}>
                <CSVLink data={stockData} filename={"AssetFilter.csv"} style={{color: 'inherit'}}>CSV</CSVLink>
              </Button>
            </Space>
          )}
          loading={loading}
          dataSource={stockData}
          columns={resultColumns}
          scroll={{ x: 500, y: 500 }}
          />
          <EditableTable
            title={() => (
              <Space>
                <Typography.Title level={4}>
                  PREÇO
                </Typography.Title>
                <Button
                  disabled={priceData.length === maxPriceFilters}
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={addPriceFilter}>
                  Adicionar filtro
                </Button>
              </Space>
            )}
            loading={loading}
            data={priceData}
            form={priceForm}
            onSave={(newData: any) => setPriceData(newData)}
            columns={priceColumns}
            pagination={{ hideOnSinglePage: true }}
            scroll={{ x: 1300, y: 500 }}
          />

          <EditableTable
            title={() => (
              <Space>
                <Typography.Title level={4}>
                  VOLUME
                </Typography.Title>
                <Button
                  disabled={volumeData.length === maxVolumeFilters}
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={addVolumeFilter}>
                  Adicionar filtro
                </Button>
              </Space>
            )}
            loading={loading}
            data={volumeData}
            form={volumeForm}
            onSave={(newData: any) => setVolumeData(newData)}
            columns={volumeColumns}
            pagination={{ hideOnSinglePage: true }}
            scroll={{ x: 1300, y: 500 }}
          />
          <EditableTable
            title={() => (
              <Space>
                <Typography.Title level={4}>
                  VOL/NEG
                </Typography.Title>
                <Button
                  disabled={ratioData.length === maxRatioFilters}
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={addRatioFilter}>
                  Adicionar filtro
                </Button>
              </Space>
            )}
            loading={loading}
            data={ratioData}
            form={ratioForm}
            onSave={(newData: any) => setRatioData(newData)}
            columns={ratioColumns}
            pagination={{ hideOnSinglePage: true }}
            scroll={{ x: 1300, y: 500 }}
          />
        </Col>
      </Row>
    </Row>
  );
}

export default AssetFilter;
