import React, {
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

import {
  Tree,
  Modal,
  Button,
  Tabs,
  Form,
  Input,
  Divider,
  message
} from 'antd';
import {
  EditOutlined,
  SisternodeOutlined,
  DeleteOutlined
} from '@ant-design/icons';

import StockApiProvider from '../../../providers/StockApiProvider';

interface TreeNodeData {
  title: string | React.ReactNode,
  key: string,
  children: Array<TreeNodeData>
}

const Metadata: FunctionComponent = () => {

  const [nodeData, setNodeData] = useState<Array<TreeNodeData>>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [rootModalVisible, setRootModalVisible] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [editForm] = Form.useForm();
  const [addForm] = Form.useForm();
  const [deleteForm] = Form.useForm();
  const [addRootForm] = Form.useForm();

  const submit_style = [
    { text: 'Editar', icon: <EditOutlined /> },
    { text: 'Adicionar nó', icon: <SisternodeOutlined /> },
    { text: 'Deletar', icon: <DeleteOutlined /> },
  ];

  const fetchTreeData = () => {
    StockApiProvider.get(`class/`)
      .then((r: any) => {
        const tmpNode = [...r.data];
        setNodeData([...tmpNode]);
      });
  }

  useEffect(() => {
    fetchTreeData();
  }, []);

  const makeTreeData = (unprocessed_data: Array<any>): Array<TreeNodeData> => {

    let tree: Array<TreeNodeData> = [];

    for (let i = 0; i < unprocessed_data.length; i++) {
      const node = unprocessed_data[i];
      tree.push({
        title: node.value ? `${node.name}: ${node.value}` : `${node.name}`,
        key: node.id,
        children: makeTreeData(node.children)
      })
    }

    return tree;
  }

  const onSelect = (selectedKeys: any, info: any) => {
    const selected = info.node;
    let tmp_node = {
      id: selected.key,
      name: selected.title.split(':')[0],
      value: selected.title.split(':')[1] || null
    };

    if (tmp_node) {
      editForm.setFieldsValue({
        id: tmp_node.id,
        name: tmp_node.name,
        value: tmp_node.value
      });
      addForm.setFieldsValue({
        id: `${tmp_node.id}_`,
        parent: tmp_node.id
      });
      deleteForm.setFieldsValue({
        id: tmp_node.id
      });
      setModalVisible(true);
      setLoading(false);
    }
  };

  const handleOk = (root: boolean = false) => {

    const finish = (): void => {
      setLoading(false);
      setModalVisible(false);
      setRootModalVisible(false);
      fetchTreeData();
      message.success('Dados atualizados');
    }

    if (root) {
      addRootForm.validateFields()
        .then(values => {
          setLoading(true);
          const { id, name, value } = values;
          StockApiProvider.post('class/', {
            id,
            name,
            value: value || null,
            parent: null
          })
            .then(r => finish())
        })
        .catch(err => { });
      return;
    }

    switch (activeTab) {
      case 0: // Editar nó
        editForm.validateFields()
          .then(values => {
            const { id, name, value } = values;
            StockApiProvider.patch(`class/${values.id}/`, {
              id,
              name,
              value: value || null
            })
              .then(r => finish());
          })
          .catch(err => { })
        break;
      case 1: // Adicionar nó
        addForm.validateFields()
          .then(values => {
            const { id, name, value, parent } = values;
            StockApiProvider.post('class/', {
              id,
              name,
              value: value || null,
              parent: parent || null
            })
              .then(r => finish());
          })
          .catch(err => { });
        break;
      case 2: // Deletar
        deleteForm.validateFields()
          .then(values => {
            StockApiProvider.delete(`class/${values.id}/`)
              .then(r => finish());
          })
          .catch(err => { });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setRootModalVisible(true)}
        icon={submit_style[1].icon}>
        Adicionar nó raiz
      </Button>
      <Modal
        title="Adicionar nó raiz"
        visible={rootModalVisible}
        confirmLoading={loading}
        onCancel={() => setRootModalVisible(false)}
        onOk={() => handleOk(true)}>
        <Form
          form={addRootForm}>
          <Form.Item name="id" rules={[{ required: true, message: 'ID inválido' }]}>
            <Input placeholder="Id" />
          </Form.Item>
          <Form.Item name="name" rules={[{ required: true, message: 'Nome inválido' }]}>
            <Input placeholder="Nome" />
          </Form.Item>
          <Form.Item name="value">
            <Input placeholder="Valor" />
          </Form.Item>
        </Form>
      </Modal>
      <Divider />
      <Tree
        onSelect={onSelect}
        treeData={makeTreeData(nodeData)}
      />
      <Modal
        title={null}
        visible={modalVisible}
        confirmLoading={loading}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setModalVisible(false)}>
            Cancelar
          </Button>,
          <Button key="submit" danger={activeTab === 2} type="primary" loading={loading} onClick={() => handleOk()} icon={submit_style[activeTab].icon}>
            {submit_style[activeTab].text}
          </Button>,
        ]}>
        <Tabs defaultActiveKey="0" onChange={(key: string) => { setActiveTab(parseInt(key)) }}>
          <Tabs.TabPane tab="Editar" key="0">
            <Form form={editForm}>
              <Form.Item name="id" style={{ display: 'none' }}>
                <Input placeholder="Id" />
              </Form.Item>
              <Form.Item name="name" rules={[{ required: true, message: 'Nome inválido' }]}>
                <Input placeholder="Nome" />
              </Form.Item>
              <Form.Item name="value">
                <Input placeholder="Valor" />
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Adicionar nó filho" key="1">
            <Form form={addForm}>
              <Form.Item name="id" rules={[{ required: true, message: 'Id inválido' }]}>
                <Input placeholder="Id" />
              </Form.Item>
              <Form.Item name="name" rules={[{ required: true, message: 'Nome inválido' }]}>
                <Input placeholder="Nome" />
              </Form.Item>
              <Form.Item name="value">
                <Input placeholder="Valor" />
              </Form.Item>
              <Form.Item name="parent">
                <Input placeholder="Parente" disabled />
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Deletar" key="2">
            <Form form={deleteForm}>
              <Form.Item name="id" style={{ display: 'none' }}>
                <Input placeholder="Id" />
              </Form.Item>
            </Form>
            Deseja apagar o nó?
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );
}

export default Metadata;
