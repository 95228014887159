import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';

import Login from './components/Login';
import Panel from './components/Panel';
import styled from 'styled-components';

const Ribbon = styled.div`
/* common */
width: 200px;
height: 200px;
overflow: hidden;
position: absolute;
z-index: 99;

span {
  position: absolute;
  display: block;
  width: 275px;
  padding: 15px 0;
  background-color: #faad14;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top right*/
top: -10px;
right: -10px;

span {
  left: -25px;
  top: 65px;
  transform: rotate(45deg);
}
`;

function App() {
  const isProduction = process.env.REACT_APP_ENVIROMENT === 'production';

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/panel">
            {!isProduction &&
            <Ribbon><span>HOMOLOGAÇÃO</span></Ribbon>}
            <Panel />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
