import React, {
  FunctionComponent,
  useState,
} from 'react';

import {
  Form,
  Checkbox,
  DatePicker,
  Button,
  Table,
  InputNumber,
  message
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import moment from 'moment';

import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from "react-csv";

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

const TopsAndBottoms: FunctionComponent<Props> = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const [stockData, setStockData] = useState<Array<any>>([]);

  const { latestDate } = props.dates;

  // Headers for React-CSV
  const headers = [
    { label: 'Trading Code', key: 'tradingcode' },
    { label: 'Short Name', key: 'shortname' },
    { label: 'Average Volume', key: 'avg_volume' },
    { label: 'RSI', key: 'rsi' },
    { label: 'Closing Price', key: 'closingprice' },
    { label: 'Oscillation', key: 'oscillation' },
    { label: 'Qtd T/F', key: 'tops_and_bottoms.count' },
    { label: 'Qtd Pregões', key: 'tops_and_bottoms.trades' },
    { label: 'Topo ou Fundo', key: 'tops_and_bottoms.type' },
  ];

  const columns: any = [
    {
      title: 'Posição',
      render: (text: any, record: any, index: any) => index + 1,
      fixed: 'left',
    },
    {
      title: 'Ativo',
      dataIndex: 'tradingcode',
      fixed: 'left',
    },
    {
      title: 'Nome',
      dataIndex: 'shortname',
    },
    {
      title: 'Qtd T/F',
      dataIndex: ['tops_and_bottoms', 'count'],
      sorter: (a: any, b: any) => a.tops_and_bottoms.count - b.tops_and_bottoms.count,
    },
    {
      title: 'Topo ou Fundo',
      dataIndex: ['tops_and_bottoms', 'type'],
      filters: [
        {
          text: 'Topo',
          value: 'Top',
        },
        {
          text: 'Fundo',
          value: 'Bottom',
        },
      ],
      onFilter: (value: any, record: any) => record.tops_and_bottoms.type.indexOf(value) !== -1,
      render: (value: string) => value === 'Top' ? 'Topo' : 'Fundo',
    },
    {
      title: 'Qtd Pregões',
      dataIndex: ['tops_and_bottoms', 'trades'],
      sorter: (a: any, b: any) => a.tops_and_bottoms.trades - b.tops_and_bottoms.trades,
    },
    {
      title: 'Volume Médio',
      dataIndex: 'avg_volume',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'RSI',
      dataIndex: 'rsi',
      sorter: (a: any, b: any) => a.rsi - b.rsi,
    },
    {
      title: 'Ult. Cot',
      dataIndex: 'closingprice',
      render: (value: number) => `R$ ${value.toFixed(2)}`.replace(".", ","),
    },
    {
      title: 'Ult. Osc',
      dataIndex: 'oscillation',
      sorter: (a: any, b: any) => a.oscillation - b.oscillation,
      render: (value: number) => `${value.toFixed(2)} %`,
    },
  ];

  const onFinish = (values: any) => {
    console.log(values);

    const date: string = moment(values.date).format('YYYY-MM-DD');
    const corrected: boolean = values.corrected;
    const avgvolume: number = values.avgvolume;

    setLoading(true);
    StockApiProvider.get(`topsbottoms/?date=${date}&volume=${avgvolume}&corrected=${corrected}`)
      .then((r: any) => {
        const { data } = r;
        if (data.length === 0)
          message.info('Nenhum dado encontrado');
        else
          message.success('Pesquisa finalizada');
        console.log(data);
        setStockData([...data]);
        setLoading(false);
      }).catch(() => setLoading(false));
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        layout="inline"
        style={{ height: '60px' }}>
        <Form.Item
          name="date"
          label="Data de corte"
          initialValue={moment(latestDate)}
          rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
          <DatePicker
            format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="avgvolume"
          label="Volume médio acima de"
          initialValue={1000000}
          rules={[{ required: true, message: 'Por favor preencher o volume médio' }]}>
          <InputNumber min={1000} />
        </Form.Item>

        <Form.Item name="corrected" valuePropName="checked" initialValue={true}>
          <Checkbox>Preço corrigido</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary">
            Pesquisar
        </Button>
        </Form.Item>
      </Form>

      <Table
        title={() => (
          <Button
            disabled={stockData.length === 0}
            type="primary"
            icon={<DownloadOutlined />}>
            <CSVLink data={stockData} headers={headers} filename={"TopsAndBottoms.csv"} style={{color: 'inherit'}}>Download</CSVLink>
          </Button>
        )}
        loading={loading}
        dataSource={stockData}
        columns={columns}
        scroll={{ x: 1300, y: 500 }}/>
    </>
  );
}

export default TopsAndBottoms;
