import React, {
  FunctionComponent,
  useState,
} from 'react';

import {
  Form,
  Checkbox,
  DatePicker,
  Button,
  Table,
  InputNumber,
  message
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import moment from 'moment';

import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from 'react-csv'

interface Stock {
  tradingfloordate: string,
  tradingcode: string,
  shortname: string,
  volume_avg1: number,
  volume_avg2: number,
  volume_osc: number,
  trades_avg1: number,
  trades_avg2: number,
  trades_osc: number,
  closingprice: number,
  oscillation: number
};

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

const VariationEvolution: FunctionComponent<Props> = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const [stockData, setStockData] = useState<Array<Stock>>([]);

  const { latestDate } = props.dates;

  const columns: any = [
    {
      title: 'Posição',
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: 'Ativo',
      dataIndex: 'tradingcode',
    },
    {
      title: 'Nome',
      dataIndex: 'shortname',
    },
    {
      title: 'M1 Volume',
      dataIndex: 'volume_avg1',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'M2 Volume',
      dataIndex: 'volume_avg2',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: '%Osc.Vol',
      dataIndex: 'volume_osc',
      sorter: (a: any, b: any) => a.volume_osc - b.volume_osc,
      defaultSortOrder: 'descend',
    },
    {
      title: 'M1 Negócios',
      dataIndex: 'trades_avg1',
      render: (value: number) => Math.ceil(value),
    },
    {
      title: 'M2 Negócios',
      dataIndex: 'trades_avg2',
      render: (value: number) => Math.ceil(value),
    },
    {
      title: '%Osc.Neg',
      dataIndex: 'trades_osc',
      sorter: (a: any, b: any) => a.trades_osc - b.trades_osc,
    },
    {
      title: 'Ult.Cot',
      dataIndex: 'closingprice',
      render: (value: number) => `R$ ${value.toFixed(2)}`.replace(".", ","),
    },
    {
      title: 'Ult.Osc',
      dataIndex: 'oscillation',
    },
  ];

  const onFinish = (values: any) => {
    console.log(values);

    const date: string = moment(values.date).format('YYYY-MM-DD');
    const avgassets: number = values.avgassets;
    const totalassets: number = values.totalassets;
    const avgvolume: number = values.avgvolume;
    const corrected: boolean = values.corrected;

    setLoading(true);
    StockApiProvider.get(`variationevolution/?date=${date}&avg=${avgassets}&total=${totalassets}&volume=${avgvolume}&corrected=${corrected}`)
      .then((r: any) => {
        const { data } = r;
        if (data.length === 0)
          message.info('Nenhum dado encontrado');
        else
          message.success('Pesquisa finalizada');
        setStockData([...data]);
        setLoading(false);
      }).catch(() => setLoading(false));
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        layout="inline"
        style={{ height: '60px' }}>
        <Form.Item
          name="date"
          label="Data de corte"
          initialValue={moment(latestDate)}
          rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
          <DatePicker
            format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="avgassets"
          label="Média Pregões"
          initialValue={2}
          rules={[{ required: true, message: 'Por favor preencher a média de pregões' }]}>
          <InputNumber min={2} />
        </Form.Item>

        <Form.Item
          name="totalassets"
          label="Total Pregões"
          initialValue={20}
          rules={[{ required: true, message: 'Por favor preencher o total de pregões' }]}>
          <InputNumber min={2} />
        </Form.Item>

        <Form.Item
          name="avgvolume"
          label="Volume médio acima de"
          initialValue={1000000}
          rules={[{ required: true, message: 'Por favor preencher o volume médio' }]}>
          <InputNumber min={1000} />
        </Form.Item>

        <Form.Item name="corrected" valuePropName="checked" initialValue={true}>
          <Checkbox>Preço corrigido</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary">
            Pesquisar
        </Button>
        </Form.Item>
      </Form>

      <Table
        title={() => (
          <Button
            disabled={stockData.length === 0}
            type="primary"
            icon={<DownloadOutlined />}>
            <CSVLink data={stockData} filename={"Variation_Evolution.csv"} style={{color: 'inherit'}}>Download</CSVLink>
          </Button>
        )}
        loading={loading}
        dataSource={stockData}
        columns={columns} 
        scroll={{ y: 500 }}/>
    </>
  );
}

export default VariationEvolution;
