import React, {
  FunctionComponent,
  useState,
} from 'react';

import {
  Form,
  Checkbox,
  DatePicker,
  Button,
  Table,
  InputNumber,
  message
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import moment from 'moment';

import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from "react-csv";

interface Stock {
  tradingfloordate: string,
  shortname: string,
  closingprice: number,
  minimumprice: number,
  maximumprice: number,
  averageprice: number,
  average_trades: number,
  average_volume: number,
  rsi: number,
  oscillation: number
};

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

const Variations: FunctionComponent<Props> = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const [stockData, setStockData] = useState<Array<Stock>>([]);

  const { firstDate, latestDate } = props.dates;

  const columns: any = [
    {
      title: 'Posição',
      render: (text: any, record: any, index: any) => index + 1,
      fixed: 'left',
    },
    {
      title: 'Ativo',
      dataIndex: 'tradingcode',
      fixed: 'left',
    },
    {
      title: 'Nome',
      dataIndex: 'shortname',
    },
    {
      title: 'Minimo',
      dataIndex: 'minimumprice',
      render: (value: number) => `R$ ${value.toFixed(2)}`.replace(".", ","),
    },
    {
      title: 'Médio',
      dataIndex: 'averageprice',
      render: (value: number) => `R$ ${value.toFixed(2)}`.replace(".", ","),
    },
    {
      title: 'Máximo',
      dataIndex: 'maximumprice',
      render: (value: number) => `R$ ${value.toFixed(2)}`.replace(".", ","),
    },
    {
      title: 'Fechamento',
      dataIndex: 'closingprice',
      render: (value: number) => `R$ ${value.toFixed(2)}`.replace(".", ","),
    },
    {
      title: 'Max/Min Período',
      dataIndex: 'maxmin_period',
      sorter: (a: any, b: any) => a.maxmin_period - b.maxmin_period,
    },
    {
      title: 'Max/Min Dia',
      dataIndex: 'maxmin_day',
      sorter: (a: any, b: any) => a.maxmin_day - b.maxmin_day,
    },
    {
      title: 'Volume',
      dataIndex: 'volume',
      sorter: (a: any, b: any) => a.volume - b.volume,
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Padrões de Candle',
      dataIndex: 'candle_pattern',
      filters: [
        {
          text: 'Engolfo de Alta',
          value: 'Engolfo de Alta',
        },
        {
          text: 'Engolfo de Baixa',
          value: 'Engolfo de Baixa',
        },
        {
          text: 'Harami de Alta',
          value: 'Harami de Alta',
        },
        {
          text: 'Harami de Baixa',
          value: 'Harami de Baixa',
        },
        {
          text: 'Bebê Abandonado de Alta',
          value: 'Bebê Abandonado de Alta',
        },
        {
          text: 'Bebê Abandonado de Baixa',
          value: 'Bebê Abandonado de Baixa',
        },
        {
          text: 'Elefante de Alta',
          value: 'Elefante de Alta',
        },
        {
          text: 'Elefante de Baixa',
          value: 'Elefante de Baixa',
        },
        {
          text: 'PIN BAR de Alta',
          value: 'PIN BAR de Alta',
        },
        {
          text: 'PIN BAR de Baixa',
          value: 'PIN BAR de Baixa',
        },
      ],
      onFilter: (value: any, record: any) => record.candle_pattern.indexOf(value) !== -1,
      render: (value: Array<string>) => value.join(', '),
    },
    {
      title: 'Negócios Médio',
      dataIndex: 'average_trades',
      sorter: (a: any, b: any) => a.average_trades - b.average_trades,
      render: (value: number) => Math.ceil(value).toLocaleString('pt-BR'),
    },
    {
      title: 'Volume Médio',
      dataIndex: 'average_volume',
      sorter: (a: any, b: any) => a.average_volume - b.average_volume,
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'RSI',
      dataIndex: 'rsi',
      sorter: (a: any, b: any) => a.rsi - b.rsi,
    },
    {
      title: '%Osc.Ac',
      dataIndex: 'acc_oscillation',
      sorter: (a: any, b: any) => a.acc_oscillation - b.acc_oscillation,
    },
  ];

  const onFinish = (values: any) => {
    console.log(values);

    const start_date: string = moment(values.daterange[0]._d).format('YYYY-MM-DD');
    const end_date: string = moment(values.daterange[1]._d).format('YYYY-MM-DD');
    const corrected: boolean = values.corrected;
    const avgvolume: number = values.avgvolume;

    const query_url = `variations/?start=${start_date}&end=${end_date}&volume=${avgvolume}&corrected=${corrected}`;

    setLoading(true);
    StockApiProvider.get(query_url)
      .then((r: any) => {
        const { data } = r;
        if (data.length === 0)
          message.info('Nenhum dado encontrado');
        else
          message.success('Pesquisa finalizada');
        setStockData([...data]);
        setLoading(false);
      }).catch(() => setLoading(false));
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        layout="inline"
        style={{ height: '60px' }}>
        <Form.Item
          name="daterange"
          initialValue={[moment(firstDate), moment(latestDate)]}
          rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
          <DatePicker.RangePicker
            ranges={{
              'Hoje': [moment(), moment()],
              'Mês atual': [moment().startOf('month'), moment()],
              'Ano atual': [moment().startOf('year'), moment()],
            }}
            format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="avgvolume"
          label="Volume médio acima de"
          initialValue={1000000}
          rules={[{ required: true, message: 'Por favor preencher o volume médio' }]}>
          <InputNumber min={1000} />
        </Form.Item>

        <Form.Item name="corrected" valuePropName="checked" initialValue={true}>
          <Checkbox>Preço corrigido</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary">
            Pesquisar
        </Button>
        </Form.Item>
      </Form>

      <Table
        title={() => (
          <Button
            disabled={stockData.length === 0}
            type="primary"
            icon={<DownloadOutlined />}>
            <CSVLink data={stockData} filename={"Variations.csv"} style={{color: 'inherit'}}>Download</CSVLink>
          </Button>
        )}
        loading={loading}
        dataSource={stockData}
        columns={columns}
        scroll={{ x: 1300, y: 500 }}/>
    </>
  );
}

export default Variations;
