import React, {
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

import {
  Form,
  Checkbox,
  DatePicker,
  Button,
  Table,
  InputNumber,
  TreeSelect,
  message,
  Row,
  Col,
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import moment from 'moment';

import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from "react-csv";

interface TreeNodeData {
  title: string | React.ReactNode,
  value: string,
  children: Array<TreeNodeData>
};

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

const Correlations: FunctionComponent<Props> = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [nodeData, setNodeData] = useState<Array<any>>([]);
  const [stockData, setStockData] = useState<Array<any>>([]);

  const [mainForm] = Form.useForm();

  const { latestDate } = props.dates;

  const formItemLayout =
  !dataLoaded
    ? {
        labelCol: { span: 16 },
        wrapperCol: { span: 16 },
      }
    : null;

  const columns: any = [
    {
      title: 'Posição',
      render: (text: any, record: any, index: any) => index + 1,
      fixed: 'left',
    },
    {
      title: 'Ativo 1',
      dataIndex: 'asset1',
      fixed: 'left',
    },
    {
      title: 'Ativo 2',
      dataIndex: 'asset2',
      fixed: 'left',
    },
    {
      title: 'Corr.DC1',
      dataIndex: 'corr_dc1',
    },
    {
      title: 'Corr.DC2',
      dataIndex: 'corr_dc2',
    },
    {
      title: 'Corr.DC3',
      dataIndex: 'corr_dc3',
    },
    {
      title: 'Dif.Corr.DC2-DC1',
      dataIndex: 'diff_corr_dc2-dc1',
      sorter: (a: any, b: any) => a['diff_corr_dc2-dc1'] - b['diff_corr_dc2-dc1'],
      filters: [
        { text: 'Maior que 0', value: 'greater_zero',},
        { text: 'Menor que 0', value: 'lesser_zero',},
      ],
      onFilter: (value: any, record: any) => value === 'greater_zero' ? record['diff_corr_dc2-dc1'] > 0 : record['diff_corr_dc2-dc1'] < 0,
      render: (text: any, record: any) => {
        let _style = {}
        if (record['diff_corr_dc2-dc1'] < 0 && record['diff_corr_dc3-dc2'] >= 0) _style={color: '#52c41a'}
        if (record['diff_corr_dc2-dc1'] > 0 && record['diff_corr_dc3-dc2'] <= 0) _style={color: '#ff4d4f'}
        return <span style={_style}>{text}</span>
      }
    },
    {
      title: 'Dif.Corr.DC3-DC2',
      dataIndex: 'diff_corr_dc3-dc2',
      sorter: (a: any, b: any) => a['diff_corr_dc3-dc2'] - b['diff_corr_dc3-dc2'],
      filters: [
        { text: 'Maior que 0', value: 'greater_zero',},
        { text: 'Menor que 0', value: 'lesser_zero',},
      ],
      onFilter: (value: any, record: any) => value === 'greater_zero' ? record['diff_corr_dc3-dc2'] > 0 : record['diff_corr_dc3-dc2'] < 0,
    },
    {
      title: 'Dif.Corr.DC3-DC1',
      dataIndex: 'diff_corr_dc3-dc1',
      sorter: (a: any, b: any) => a['diff_corr_dc3-dc1'] - b['diff_corr_dc3-dc1'],
      filters: [
        { text: 'Maior que 0', value: 'greater_zero',},
        { text: 'Menor que 0', value: 'lesser_zero',},
      ],
      onFilter: (value: any, record: any) => value === 'greater_zero' ? record['diff_corr_dc3-dc1'] > 0 : record['diff_corr_dc3-dc1'] < 0,
      render: (text: any, record: any) => {
        let _style = {}
        if (record['diff_corr_dc3-dc1'] < 0 && record['diff_corr_dc2-dc1'] >= 0) _style={color: '#52c41a'}
        if (record['diff_corr_dc3-dc1'] > 0 && record['diff_corr_dc2-dc1'] <= 0) _style={color: '#ff4d4f'}
        return <span style={_style}>{text}</span>
      }
    },
    {
      title: ' Ult.Cot Ativo 1',
      dataIndex: 'asset1_closingprice',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: ' Ult.Osc Ativo 1',
      dataIndex: 'asset1_oscillation',
    },
    {
      title: ' Ult.Cot Ativo 2',
      dataIndex: 'asset2_closingprice',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: ' Ult.Osc Ativo 2',
      dataIndex: 'asset2_oscillation',
    },
  ];

  useEffect(() => {
    mainForm.setFieldsValue({
      date1: moment(latestDate),
      date2: moment(latestDate).subtract(1, 'weeks'),
      date3: moment(latestDate).subtract(1, 'months'),
    });
    // Get asset list
    StockApiProvider.get('class/listasativos_/')
      .then((r: any) => {
        setNodeData([...r.data]);
      })
      .catch(() => { });
  }, [mainForm, latestDate]);

  const makeTreeData = (unprocessed_data: Array<any>): Array<TreeNodeData> => {

    let tree: Array<TreeNodeData> = [];

    for (let i = 0; i < unprocessed_data.length; i++) {
      const node = unprocessed_data[i];
      tree.push({
        title: node.value ? `${node.name}: ${node.value}` : `${node.name}`,
        value: node.id,
        children: makeTreeData(node.children)
      })
    }

    return tree;
  }

  const onFinish = (values: any) => {
    console.log(values);

    const {
      date1, date2, date3,
      trades1, trades2, trades3,
      avgvolume, corrected,
      assetlist
    } = values;

    const query_url = `
    correlation/${assetlist}
    ?date1=${moment(date1).format('YYYY-MM-DD')}
    &date2=${moment(date2).format('YYYY-MM-DD')}
    &date3=${moment(date3).format('YYYY-MM-DD')}
    &trades1=${trades1}
    &trades2=${trades2}
    &trades3=${trades3}
    &volume=${avgvolume}
    &corrected=${corrected}`.replace(/\s\s+/g, '').trim();

    console.log(query_url)

    setLoading(true);
    StockApiProvider.get(query_url)
      .then((r: any) => {
        const { data } = r;
        if (data.length === 0)
          message.info('Nenhum dado encontrado');
        else
          message.success('Pesquisa finalizada');
        console.log(data);
        setStockData([...data]);
        setLoading(false);
        setDataLoaded(true);
      }).catch(() => {
        message.error('Não foram encontradas correlações nas datas especificadas')
        setLoading(false)
      });
  };

  return (
    <>
      <Form
        {...formItemLayout}
        form={mainForm}
        onFinish={onFinish}
        layout={dataLoaded ? "inline" : "vertical"}
        style={{maxWidth: 700}}>
        {[1, 2, 3].map((value: number) => (
        <Form.Item key={value}>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
            name={"date" + value}
            label={"Data de corte " + value}
            initialValue={moment()}
            rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
              <DatePicker style={{width: '100%'}} format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={"trades" + value}
              label="Total Pregões"
              initialValue={100}
              rules={[{ required: true, message: 'Por favor preencher o total de pregões' }]}>
              <InputNumber style={{width: '50%'}} min={2} />
            </Form.Item>
          </Col>
        </Row>
        </Form.Item>
        ))}

        <Form.Item
          name="avgvolume"
          label="Volume médio acima de"
          initialValue={1000000}
          rules={[{ required: true, message: 'Por favor preencher o volume médio' }]}>
          <InputNumber min={1000} />
        </Form.Item>

        <Form.Item name="corrected" valuePropName="checked" initialValue={true}>
          <Checkbox>Preço corrigido</Checkbox>
        </Form.Item>

        <Form.Item
          name="assetlist"
          label="Lista de Ativos"
          rules={[{ required: true, message: 'Por favor selecionar a lista de ativos' }]}>
          <TreeSelect
            style={{ minWidth: 250, width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={makeTreeData(nodeData)}
            placeholder="Selecionar lista"
          />
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary">
            Pesquisar
        </Button>
        </Form.Item>
      </Form>
      {(dataLoaded && stockData) &&
      <Table
        title={() => (
          <Button
            disabled={stockData.length === 0}
            type="primary"
            icon={<DownloadOutlined />}>
            <CSVLink data={stockData} filename={"Correlations.csv"} style={{color: 'inherit'}}>Download</CSVLink>
          </Button>
        )}
        loading={loading}
        dataSource={stockData}
        columns={columns}
        scroll={{ x: 1300, y: 500 }}/>
      }
    </>
  );
}

export default Correlations;
