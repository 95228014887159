import { all } from 'redux-saga/effects'

import loadSagas from '../load/sagas';

function* rootSaga() {
  yield all([
    ...loadSagas,
  ])
}

export default rootSaga;
