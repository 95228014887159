import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import {
  Typography,
  Form,
  Tag,
  Checkbox,
  Select,
  DatePicker,
  Button,
  Descriptions,
  Card,
  Statistic,
  Row,
  Col,
  message
} from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  MinusOutlined
} from '@ant-design/icons';

import moment from 'moment';

import StockApiProvider from '../../../providers/StockApiProvider';

interface Option {
  value: string,
  label: ReactElement | string
};

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

const AssetEvolution: FunctionComponent<Props> = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [assetData, setAssetData] = useState<Array<Option>>([]);
  const [bdiData, setBDIData] = useState<Array<Option>>([]);
  const [marketData, setMarketData] = useState<Array<Option>>([]);

  const [stockData, setStockData] = useState<any>({});

  const { firstDate, latestDate } = props.dates;

  const formItemLayout =
    !dataLoaded
      ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 10 },
        }
      : null;
  
  const periods: Array<string> = ["week", "month", "semester", "year"]
  const period_sub: any = {
    "week": "Semana",
    "month": "Mês",
    "semester": "Semestre",
    "year": "Ano"
  }

  useEffect(() => {
    setLoading(true);
    StockApiProvider.get('assets/')
      .then((r: any) => {
        const { data } = r;
        const tmp_options: Option[] = data.map((asset: any) => ({
          value: asset.tradingcode,
          label: (
            <>
              <Tag>{asset.tradingcode}</Tag>
              <span>{asset.shortname}</span>
            </>
          )
        }));
        setAssetData([...tmp_options]);
      });
    // TIPO NEGOCIO, TIPO MERCADO
    Promise.all([
      StockApiProvider.get('class/bdi/'),
      StockApiProvider.get('class/market/')])
      .then(r => {
        const bdilist = r[0].data;
        const marketlist = r[1].data;

        if (bdilist.length && marketlist.length) {
          let tmp_bdi: Array<Option> = bdilist[0].children.map((bdi: any) => ({ value: bdi.name, label: bdi.value }));
          let tmp_market: Array<Option> = marketlist[0].children.map((market: any) => ({ value: market.name, label: market.value }));
          setBDIData([...tmp_bdi]);
          setMarketData([...tmp_market]);
        }
        setLoading(false);
      })
      .catch(err => { })
  }, []);

  const onFinish = (values: any) => {

    const tradingcode: string = values.tradingcode;
    const start: string = moment(values.daterange[0]._d).format('YYYY-MM-DD');
    const end: string = moment(values.daterange[1]._d).format('YYYY-MM-DD');
    const corrected: boolean = values.corrected;
    const bdi: string = values.bdi;
    const market: string = values.market;

    setLoading(true);
    StockApiProvider.get(`assetevolution/${tradingcode}?start=${start}&end=${end}&bdi=${bdi}&market=${market}&corrected=${corrected}`)
      .then((r: any) => {
        const { data } = r;
        message.success('Pesquisa finalizada');
        setStockData({...data});
        setDataLoaded(true);
        setLoading(false);
      }).catch(() => {
        message.info('Nenhum dado encontrado');
        setLoading(false)
      });
  };

  const format_money = (value: number) => {
    if (!value) value = 0;
    return `R$ ${value.toFixed(2)}`.replace(".", ",");
  }

  return (
    <>
      <Form
        {...formItemLayout}
        onFinish={onFinish}
        layout={dataLoaded ? "inline" : "horizontal"}
        style={{ height: '60px'}}>
        <Form.Item
          name="daterange"
          initialValue={[moment(firstDate), moment(latestDate)]}
          rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
          <DatePicker.RangePicker
            ranges={{
              'Hoje': [moment(), moment()],
              'Mês atual': [moment().startOf('month'), moment().endOf('month')],
              'Ano atual': [moment().startOf('year'), moment().endOf('year')],
            }}
            format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="tradingcode"
          rules={[{ required: true, message: 'Por favor inserir o código' }]}>
          <Select
            style={{ minWidth: 200 }}
            showSearch
            showArrow={false}
            placeholder="Código BDI"
            optionFilterProp="value"
            filterOption={(input, option: any) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {assetData.map((option: Option) => {
              return <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
            })}
          </Select>
        </Form.Item>

        <Form.Item name="bdi" initialValue="02">
          <Select loading={loading}>
            {bdiData.map((op: Option) => <Select.Option key={op.value} value={op.value}>{op.label}</Select.Option>)}
          </Select>
        </Form.Item>

        <Form.Item name="market" initialValue="010">
          <Select loading={loading}>
            {marketData.map((op: Option) => <Select.Option key={op.value} value={op.value}>{op.label}</Select.Option>)}
          </Select>
        </Form.Item>

        <Form.Item name="corrected" valuePropName="checked" initialValue={true}>
          <Checkbox>Preço corrigido</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary">
            Pesquisar
        </Button>
        </Form.Item>
      </Form>
      {(dataLoaded && stockData) &&
        <>
          <Row gutter={16}>
            {periods.map((period: string, index: number) => {
              const current: any = stockData.current[period];
              const last: any = stockData.last[period];

              const c_raw_osc = current.oscillation;
              const c_positive_osc: number = c_raw_osc > 0 ? c_raw_osc : -1 * c_raw_osc;
              const l_raw_osc = last.oscillation;
              const l_positive_osc: number = l_raw_osc > 0 ? l_raw_osc : -1 * l_raw_osc;
              
              const current_color = c_raw_osc >= 0 ? '#3f8600' : '#cf1322';
              const current_icon = c_raw_osc >= 0 ? <PlusOutlined /> : <MinusOutlined />;

              const last_color = l_raw_osc >= 0 ? '#3f8600' : '#cf1322';
              const last_icon = l_raw_osc >= 0 ? <PlusOutlined /> : <MinusOutlined />;
            return (
            <Col span={6} key={period}>
              <Card title={period_sub[period]}>
                <Card.Grid style={{width: '100%'}}>
                  <Card.Meta
                    title={
                      <span>
                        {index === 0 ? "Na" : "No"}
                        <Tag style={{float: 'right'}}>{moment(current.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Tag>
                      </span>
                    }
                    description={
                    <Row justify="space-around">
                      <Statistic
                        title="Fechamento"
                        value={current.closingprice}
                        precision={2}
                        prefix="R$ "
                      />
                      <Statistic
                        title="Oscilação"
                        value={c_positive_osc}
                        precision={2}
                        valueStyle={{ color:  current_color}}
                        prefix={current_icon}
                        suffix="%"
                      />
                    </Row>
                    }
                  />
                </Card.Grid>
                <Card.Grid style={{width: '100%'}}>
                  <Card.Meta
                      title={
                        <span>
                          Há
                          <Tag style={{float: 'right'}}>{moment(last.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Tag>
                        </span>
                      }
                      description={
                      <Row justify="space-around">
                        <Statistic
                          title="Fechamento"
                          value={last.closingprice}
                          precision={2}
                          prefix="R$ "
                        />
                        <Statistic
                          title="Oscilação"
                          value={l_positive_osc}
                          precision={2}
                          valueStyle={{ color:  last_color}}
                          prefix={last_icon}
                          suffix="%"
                        />
                      </Row>
                      }
                    />
                </Card.Grid>
              </Card>
            </Col>); })}
          </Row>
          <Row gutter={16} style={{marginTop: 16}}>
            <Col span={24}>
              <Typography.Title level={4}>
              Evolução no período
              </Typography.Title>
            </Col>
            <Col span={12}>
              <Descriptions bordered>
                <Descriptions.Item label="Média negócios" span={3}>{Math.round(stockData.avg_trades)}</Descriptions.Item>
                <Descriptions.Item label="Preço mínimo" span={3}>{format_money(stockData.min_price.closingprice)}</Descriptions.Item>
                <Descriptions.Item label="Preço máximo" span={3}>{format_money(stockData.max_price.closingprice)}</Descriptions.Item>
                <Descriptions.Item label="Oferta de compra" span={3}>{format_money(stockData.bestbuyofferprice)}</Descriptions.Item>
                <Descriptions.Item label="Cotação" span={3}>{format_money(stockData.closingprice)}</Descriptions.Item>
                <Descriptions.Item label="Oscilação" span={3}>{stockData.oscillation} %</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={12}>
              <Descriptions bordered>
                <Descriptions.Item label="Média volume" span={3}>{format_money(stockData.avg_volume)}</Descriptions.Item>
                <Descriptions.Item label="Data preço mínimo" span={3}>{moment(stockData.min_price.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Descriptions.Item>
                <Descriptions.Item label="Data preço máximo" span={3}>{moment(stockData.max_price.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Descriptions.Item>
                <Descriptions.Item label="Oferta de venda" span={3}>{format_money(stockData.bestsaleofferprice)}</Descriptions.Item>
                <Descriptions.Item label="Pregões" span={3}>{stockData.trades}</Descriptions.Item>
                <Descriptions.Item label="RSI" span={3}>{stockData.rsi}</Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </>}
    </>
  );
}

export default AssetEvolution;
