import React, {
  FunctionComponent,
  useState,
} from 'react';

import {
  Form,
  Checkbox,
  DatePicker,
  Button,
  Table,
  InputNumber,
  message
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import moment from 'moment';

import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from "react-csv";

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

const Congestion: FunctionComponent<Props> = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const [stockData, setStockData] = useState<Array<any>>([]);

  const { latestDate } = props.dates;

  // Headers for React-CSV
  const headers = [
    { label: 'Trading Code', key: 'tradingcode' },
    { label: 'Average Volume', key: 'avg_volume' },
    { label: 'Day Volume', key: 'day_volume' },
    { label: '% Var. Volume', key: 'volume_variation' },
    { label: 'Fx.Inicial', key: 'fx_start' },
    { label: 'Fx.Final', key: 'fx_end' },
    { label: 'Closing Price', key: 'closingprice' },
    { label: 'Tipo', key: 'congestion_type' },
    { label: '% Proximidade', key: 'proximity' },
    { label: 'Oscillation', key: 'oscillation' },
  ];

  const columns: any = [
    {
      title: 'Posição',
      render: (text: any, record: any, index: any) => index + 1,
      fixed: 'left',
    },
    {
      title: 'Ativo',
      dataIndex: 'tradingcode',
      fixed: 'left',
    },
    {
      title: 'Volume Médio',
      dataIndex: 'avg_volume',
      render: (value: number) => value.toLocaleString('pt-BR'),
    },
    {
      title: 'Volume Dia',
      dataIndex: 'day_volume',
      render: (value: number) => value.toLocaleString('pt-BR'),
    },
    {
      title: '% Var. Vol',
      dataIndex: 'volume_variation',
      render: (value: number) => `${value}%`,
      sorter: (a: any, b: any) => a.volume_variation - b.volume_variation,
    },
    {
      title: 'Fx.Inicial',
      dataIndex: 'fx_start',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Fx.Final',
      dataIndex: 'fx_end',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Fechamento',
      dataIndex: 'closingprice',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Tipo',
      dataIndex: 'congestion_type',
      filters: [
        {
          text: 'Alta',
          value: 'High',
        },
        {
          text: 'Baixa',
          value: 'Low',
        },
      ],
      onFilter: (value: any, record: any) => record.congestion_type.indexOf(value) !== -1,
      render: (value: string) => value === 'High' ? 'Alta' : 'Baixa',
    },
    {
      title: '% Proximidade',
      dataIndex: 'proximity',
      render: (value: number) => `${value}%`,
      sorter: (a: any, b: any) => a.proximity - b.proximity,
    },
    {
      title: 'Ult. Osc',
      dataIndex: 'oscillation',
      render: (value: number) => `${value}%`,
    },
  ];

  const onFinish = (values: any) => {
    const { date, avgvolume, corrected, totalassets, strip } = values;

    const date_cnv = moment(date).format('YYYY-MM-DD');

    setLoading(true);
    StockApiProvider.get(`congestion/?date=${date_cnv}&volume=${avgvolume}&corrected=${corrected}&trades=${totalassets}&strip=${strip}`)
      .then((r: any) => {
        const { data } = r;
        if (data.length === 0)
          message.info('Nenhum dado encontrado');
        else
          message.success('Pesquisa finalizada');
        console.log(data);
        setStockData([...data]);
        setLoading(false);
      }).catch(() => setLoading(false));
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        layout="inline"
        style={{ height: '60px' }}>
        <Form.Item
          name="date"
          label="Data de corte"
          initialValue={moment(latestDate)}
          rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
          <DatePicker
            format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="avgvolume"
          label="Volume médio acima de"
          initialValue={1000000}
          rules={[{ required: true, message: 'Por favor preencher o volume médio' }]}>
          <InputNumber min={1000} />
        </Form.Item>

        <Form.Item name="corrected" valuePropName="checked" initialValue={true}>
          <Checkbox>Preço corrigido</Checkbox>
        </Form.Item>

        <Form.Item
          name="totalassets"
          label="Qtd Pregões"
          initialValue={21}
          rules={[{ required: true, message: 'Por favor preencher o total de pregões' }]}>
          <InputNumber min={2} />
        </Form.Item>

        <Form.Item
          name="strip"
          label="Percentual da Faixa"
          rules={[{ required: true, message: 'Por favor preencher o campo' }]}>
          <InputNumber min={1} />
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary">
            Pesquisar
        </Button>
        </Form.Item>
      </Form>

      <Table
        title={() => (
          <Button
            disabled={stockData.length === 0}
            type="primary"
            icon={<DownloadOutlined />}>
            <CSVLink data={stockData} headers={headers} filename={"Congestion.csv"} style={{color: 'inherit'}}>Download</CSVLink>
          </Button>
        )}
        loading={loading}
        dataSource={stockData}
        columns={columns}
        scroll={{ x: 1300, y: 500 }}/>
    </>
  );
}

export default Congestion;
