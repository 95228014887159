import React, {
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

import {
  Form,
  Checkbox,
  DatePicker,
  Button,
  Select,
  TreeSelect,
  Table,
  InputNumber,
  message,
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import moment from 'moment';

import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from "react-csv";

interface TreeNodeData {
  title: string | React.ReactNode,
  value: string,
  children: Array<TreeNodeData>
};

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

const CorrelatedPairs: FunctionComponent<Props> = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [nodeData, setNodeData] = useState<Array<any>>([]);
  const [stockData, setStockData] = useState<Array<any>>([]);

  const [mainForm] = Form.useForm();

  const { latestDate } = props.dates;

  const formItemLayout =
  !dataLoaded
    ? {
        labelCol: { span: 16 },
        wrapperCol: { span: 16 },
      }
    : null;

  const columns: any = [
    {
      title: 'Posição',
      render: (text: any, record: any, index: any) => index + 1,
      fixed: 'left',
    },
    {
      title: 'Par',
      dataIndex: 'pair',
      fixed: 'left',
    },
    {
      title: 'Preço A',
      dataIndex: 'price_a',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Preço B',
      dataIndex: 'price_b',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Correl',
      dataIndex: 'corr',
      filters: [
        {
          text: '>= 0,5',
          value: '0.5',
        },
        {
          text: '>= 0,85',
          value: '0.85',
        },
      ],
      filterMultiple: false,
      onFilter: (value: any, record: any) => record.corr >= parseFloat(value),
    },
    {
      title: 'A - B',
      dataIndex: 'diff',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Média',
      dataIndex: 'average',
    },
    {
      title: 'Ratio',
      dataIndex: 'ratio',
      sorter: (a: any, b: any) => a.ratio - b.ratio,
    },
    {
      title: 'Máx',
      dataIndex: 'max',
    },
    {
      title: 'Mín',
      dataIndex: 'min',
    },
    {
      title: 'Force',
      dataIndex: 'force',
      sorter: (a: any, b: any) => a.force - b.force,
    },
  ];

  useEffect(() => {
    mainForm.setFieldsValue({
      date1: moment(latestDate),
    });
    // Get asset list
    StockApiProvider.get('class/paresativos/')
      .then(({ data }) => {
        setNodeData([...data]);
      })
      .catch(() => { });
  }, [mainForm, latestDate]);

  const makeTreeData = (unprocessed_data: Array<any>): Array<TreeNodeData> => {

    let tree: Array<TreeNodeData> = [];

    for (let i = 0; i < unprocessed_data.length; i++) {
      const node = unprocessed_data[i];
      tree.push({
        title: node.value ? `${node.name}: ${node.value}` : `${node.name}`,
        value: node.id,
        children: makeTreeData(node.children)
      })
    }

    return tree;
  };

  const onFinish = (values: any) => {
    console.log(values);

    const {
      date,
      v_cor, v_med, v_maxmin,
      corrected,
      assetlist, field
    } = values;

    const query_url = `
    correlatedpairs/${assetlist}
    ?date=${moment(date).format('YYYY-MM-DD')}
    &v_cor=${v_cor}
    &v_med=${v_med}
    &v_maxmin=${v_maxmin}
    &field=${field}
    &corrected=${corrected}`.replace(/\s\s+/g, '').trim();

    setLoading(true);
    StockApiProvider.get(query_url)
      .then((r: any) => {
        const { data } = r;
        if (data.length === 0)
          message.info('Nenhum dado encontrado');
        else
          message.success('Pesquisa finalizada');
        console.log(data);
        setStockData([...data]);
        setLoading(false);
        setDataLoaded(true);
      }).catch(() => {
        message.error('Não foram encontradas correlações nas datas especificadas')
        setLoading(false)
      });
  };

  return (
    <>
      <Form
        {...formItemLayout}
        form={mainForm}
        onFinish={onFinish}
        layout={dataLoaded ? "inline" : "vertical"}
        style={{maxWidth: 700}}>

        <Form.Item
          name="date"
          label="Data de corte"
          initialValue={moment(latestDate)}
          rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
          <DatePicker
            format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="v_cor"
          label="Pregões Correlações"
          initialValue={100}
          rules={[{ required: true, message: 'Por favor preencher pregões' }]}>
          <InputNumber min={10} max={250} />
        </Form.Item>

        <Form.Item
          name="v_med"
          label="Pregões Média"
          initialValue={100}
          rules={[{ required: true, message: 'Por favor preencher pregões' }]}>
          <InputNumber min={10} max={250} />
        </Form.Item>

        <Form.Item
          name="v_maxmin"
          label="Pregões Máx/Min"
          initialValue={100}
          rules={[{ required: true, message: 'Por favor preencher pregões' }]}>
          <InputNumber min={10} max={250} />
        </Form.Item>

        <Form.Item name="corrected" valuePropName="checked" initialValue={true}>
          <Checkbox>Preço corrigido</Checkbox>
        </Form.Item>

        <Form.Item
          name="assetlist"
          label="Lista de Ativos"
          rules={[{ required: true, message: 'Por favor selecionar a lista de ativos' }]}>
          <TreeSelect
            style={{ minWidth: 250, width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={makeTreeData(nodeData)}
            placeholder="Selecionar lista"
          />
        </Form.Item>

        <Form.Item
          name="field"
          label="Preço"
          initialValue={'closingprice'}
          rules={[{ required: true, message: 'Por favor preencher o campo de preço' }]}>
          <Select style={{ width: 120 }}>
            <Select.Option value="openingprice">Abertura</Select.Option>
            <Select.Option value="closingprice">Fechamento</Select.Option>
            <Select.Option value="minimumprice">Mínimo</Select.Option>
            <Select.Option value="averageprice">Médio</Select.Option>
            <Select.Option value="maximumprice">Máximo</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary">
            Pesquisar
          </Button>
        </Form.Item>
      </Form>
      {(dataLoaded && stockData) &&
      <Table
        title={() => (
          <Button
            disabled={stockData.length === 0}
            type="primary"
            icon={<DownloadOutlined />}>
            <CSVLink data={stockData} filename={"CorrelatedPairs.csv"} style={{color: 'inherit'}}>Download</CSVLink>
          </Button>
        )}
        loading={loading}
        dataSource={stockData}
        columns={columns}
        scroll={{ x: 1300, y: 500 }}/>
      }
    </>
  );
}

export default CorrelatedPairs;
