const initialState = {
  firstDate: '',
  latestDate: '',
}

export default function loadReducer(state = initialState, action) {
  switch (action.type) {
    case 'SUCCESS_GET_LOAD':
      return {...action.payload}
    default:
      return state
  }
}
