import { call, put, takeLatest } from 'redux-saga/effects';

import StockApiProvider from '../../providers/StockApiProvider';

function* fetchLoad() {
  try {
    const results = yield call(StockApiProvider.get, 'tradingfloor/?range=true');
    const { data } = results;
    yield put({type: 'SUCCESS_GET_LOAD', payload: { firstDate: data[0].date, latestDate: data[1].date }});

  } catch (e) {
    yield console.log(e);
    yield put({type: 'FAILURE_GET_LOAD'});
  }
}

export default [
  takeLatest('REQUEST_GET_LOAD', fetchLoad),
];
