import React, {
  FunctionComponent,
  useEffect,
  useState,
} from 'react';

import {
  Row,
  Col,
  Space,
  Typography,
  Form,
  Table,
  Button,
  TreeSelect,
  Switch,
  Input,
  InputNumber,
  message,
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import EditableTable from '../../EditableTable';

import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from "react-csv";

interface TreeNodeData {
  title: string | React.ReactNode,
  value: string,
  children: Array<TreeNodeData>
};

const BullExport: FunctionComponent = () => {

  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [nodeData, setNodeData] = useState<Array<any>>([]);
  const [stockData, setStockData] = useState<Array<any>>([]);
  const [salesList, setSalesList] = useState(true);
  const [purchasesList, setPurchasesList] = useState(true);

  const [mainForm] = Form.useForm();

  const formItemLayout =
  !dataLoaded
    ? {
        labelCol: { span: 16 },
        wrapperCol: { span: 16 },
      }
    : null;

  const columns: any = [
    {
      title: 'Operacao',
      dataIndex: 'Operacao',
      fixed: 'left',
      editable: true,
      editableList: [
        { value: 'Compra', label: 'Compra' },
        { value: 'Venda', label: 'Venda' },
      ],
    },
    {
      title: 'Ativo',
      dataIndex: 'Ativo',
      fixed: 'left',
    },
    {
      title: 'Quantidade',
      dataIndex: 'Quantidade',
      editable: true,
    },
    {
      title: 'Validade',
      dataIndex: 'Validade',
      editable: true,
      editableList: [
        { value: 'HOJE', label: 'HOJE' },
        { value: 'ATEQUECANCELE', label: 'ATEQUECANCELE' },
        { value: 'DATAESPECIFICA', label: 'DATAESPECIFICA' },
        { value: 'EXECUTAPARCIALMENTE', label: 'EXECUTAPARCIALMENTE' },
      ],
    },
    {
      title: 'ValidadeDataEspecifica',
      dataIndex: 'ValidadeDataEspecifica',
      editable: true,
    },
    {
      title: 'OrdemTipo',
      dataIndex: 'OrdemTipo',
      editable: true,
      editableList: [
        { value: 'Completa', label: 'Completa' },
        { value: 'Simples', label: 'Simples' },
        { value: 'Stop', label: 'Stop' },
      ],
    },
    {
      title: 'EntradaPreco',
      dataIndex: 'EntradaPreco',
      render: (value: number) => value.toLocaleString('pt-BR'),
      editable: true,
    },
    {
      title: 'EntradaDisparo',
      dataIndex: 'EntradaDisparo',
      render: (value: number) => value.toLocaleString('pt-BR'),
      editable: true,
    },
    {
      title: 'EntradaLimite',
      dataIndex: 'EntradaLimite',
      render: (value: number) => value.toLocaleString('pt-BR'),
      editable: true,
    },
    {
      title: 'GainDisparo',
      dataIndex: 'GainDisparo',
      render: (value: number) => value.toLocaleString('pt-BR'),
      editable: true,
    },
    {
      title: 'GainLimite',
      dataIndex: 'GainLimite',
      render: (value: number) => value.toLocaleString('pt-BR'),
      editable: true,
    },
    {
      title: 'ReducaoGainDisparo',
      dataIndex: 'ReducaoGainDisparo',
      editable: true,
    },
    {
      title: 'LossDisparo',
      dataIndex: 'LossDisparo',
      render: (value: number) => value.toLocaleString('pt-BR'),
      editable: true,
    },
    {
      title: 'LossLimite',
      dataIndex: 'LossLimite',
      render: (value: number) => value.toLocaleString('pt-BR'),
      editable: true,
    },
    {
      title: 'OrdemEscondidaQuantidadeAparente',
      dataIndex: 'OrdemEscondidaQuantidadeAparente',
      editable: true,
    },
  ];

  useEffect(() => {
    // Get asset list
    StockApiProvider.get('class/listasativos_/')
      .then(({ data }) => {
        setNodeData([...data]);
      })
      .catch(() => { });
  }, []);

  const makeTreeData = (unprocessed_data: Array<any>): Array<TreeNodeData> => {

    let tree: Array<TreeNodeData> = [];

    for (let i = 0; i < unprocessed_data.length; i++) {
      const node = unprocessed_data[i];
      tree.push({
        title: node.value ? `${node.name}: ${node.value}` : `${node.name}`,
        value: node.id,
        children: makeTreeData(node.children)
      })
    }

    return tree;
  };

  const onFinish = (values: any) => {
    console.log(values);

    const {
      op_value, stop_gain, stop_loss,
      indfut, aplic_indfut, b_price,
      sales, purchases,
    } = values;

    let query_url = `
    bullexport/
    ?op_value=${op_value}
    &stop_gain=${stop_gain}
    &stop_loss=${stop_loss}
    &indfut=${indfut}
    &aplic_indfut=${aplic_indfut}
    &b_price=${b_price}
    &sales=${sales}
    &sales_list=${salesList}
    &purchases=${purchases}
    &purchases_list=${purchasesList}`.replace(/\s\s+/g, '').trim();

    setLoading(true);
    StockApiProvider.get(query_url)
      .then((r: any) => {
        const { data } = r;
        if (data.length === 0)
          message.info('Nenhum dado encontrado');
        else
          message.success('Pesquisa finalizada');
        console.log(data);
        setStockData([...data]);
        setLoading(false);
        setDataLoaded(true);
      }).catch(() => {
        message.error('Não foram encontradas correlações nas datas especificadas')
        setLoading(false)
      });
  };

  const handleSave = (row: any) => {
    const newData = [...stockData];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setStockData([...newData]);
  };

  return (
    <Row>
      <Col span={8}>
      <Form
        {...formItemLayout}
        form={mainForm}
        onFinish={onFinish}
        layout={"vertical"}
        style={{maxWidth: 700}}>

        <Form.Item
          name="op_value"
          label="Valor por operação"
          initialValue={10000}
          rules={[{ required: true }]}>
          <InputNumber/>
        </Form.Item>

        <Form.Item
          name="stop_gain"
          label="% Stop Gain"
          initialValue={2}
          rules={[{ required: true }]}>
          <InputNumber 
            min={0}
            max={100}
            step={0.01}
            formatter={value => `${value} %`}
            parser={(value: any) => value.replace(' %', '')}/>
        </Form.Item>

        <Form.Item
          name="stop_loss"
          label="% Stop Loss"
          initialValue={5}
          rules={[{ required: true }]}>
          <InputNumber 
            min={0}
            max={100}
            step={0.01}
            formatter={value => `${value} %`}
            parser={(value: any) => value.replace(' %', '')}/>
        </Form.Item>

        <Form.Item
          name="indfut"
          label="% INDFUT"
          initialValue={0}
          rules={[{ required: true }]}>
          <InputNumber 
            min={0}
            max={100}
            step={0.01}
            formatter={value => `${value} %`}
            parser={(value: any) => value.replace(' %', '')}/>
        </Form.Item>

        <Form.Item
          name="aplic_indfut"
          label="% Aplic INDFUT"
          initialValue={50}
          rules={[{ required: true }]}>
          <InputNumber 
            min={0}
            max={100}
            step={0.01}
            formatter={value => `${value} %`}
            parser={(value: any) => value.replace(' %', '')}/>
        </Form.Item>

        <Form.Item
          name="b_price"
          label="% Preço Melhorado"
          initialValue={1}
          rules={[{ required: true }]}>
          <InputNumber 
            min={0}
            max={100}
            step={0.01}
            formatter={value => `${value} %`}
            parser={(value: any) => value.replace(' %', '')}/>
        </Form.Item>

        {/* Lista de vendas */}
        <Space>
          <Typography.Text>
            Lista de vendas
          </Typography.Text>
          <Switch
            checkedChildren="Definida"
            unCheckedChildren="Escrita"
            checked={salesList}
            onChange={(checked: boolean) => setSalesList(checked)}/>
        </Space>
        
        <Form.Item
          name="sales"
          rules={[{ required: true, message: 'Por favor selecionar a lista de ativos' }]}
          style={{ marginTop: 10 }}>
          {salesList ?
          <TreeSelect
            style={{ minWidth: 250, width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={makeTreeData(nodeData)}
            placeholder="Selecionar lista"
          />
          :
          <Input placeholder="Ativos separados por vírgula" />
          }
        </Form.Item>

        {/* Lista de compras */}
        <Space>
          <Typography.Text>
            Lista de compras
          </Typography.Text>
          <Switch
            checkedChildren="Definida"
            unCheckedChildren="Escrita"
            checked={purchasesList}
            onChange={(checked: boolean) => setPurchasesList(checked)}/>
        </Space>
        
        <Form.Item
          name="purchases"
          rules={[{ required: true, message: 'Por favor selecionar a lista de ativos' }]}
          style={{ marginTop: 10 }}>
          {purchasesList ?
          <TreeSelect
            style={{ minWidth: 250, width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={makeTreeData(nodeData)}
            placeholder="Selecionar lista"
          />
          :
          <Input placeholder="Ativos separados por vírgula" />
          }
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary">
            Calcular
          </Button>
        </Form.Item>
      </Form>
      </Col>

      <Col span={16}>
      <Table
        title={() => (
          <Button
            disabled={stockData.length === 0}
            type="primary"
            icon={<DownloadOutlined />}>
            <CSVLink data={stockData} filename={"BullExport.csv"} style={{color: 'inherit'}}>Download</CSVLink>
          </Button>
        )}
        loading={loading}
        dataSource={stockData}
        columns={columns}
        scroll={{ x: 1300, y: 500 }}/>
      </Col>
    </Row>
  );
}

export default BullExport;
