import React, {
  FunctionComponent,
  useEffect,
  useState,
  ReactElement,
} from 'react';

import {
  Row,
  Col,
  Typography,
  Form,
  Checkbox,
  DatePicker,
  TreeSelect,
  Select,
  Button,
  Tag,
  message
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import moment from 'moment';
import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from 'react-csv';

const possibleHeaders = [
  {
    key: "Mercado e Ativo",
    headers: [
      { label: "Data do pregão", key: "tradingfloordate" },
      { label: "Código BDI", key: "bdicode" },
      { label: "Tipo de Mercado", key: "markettype" },
      { label: "Código do Ativo", key: "tradingcode" },
      { label: "Nome curto", key: "shortname" },
      { label: "Especificação do Ativo", key: "assetspecification" },
      { label: "Código ISIN do Ativo", key: "isincode" },
      { label: "Número de Distribuição", key: "distributionnumber" },
      { label: "Fator de Cotação", key: "quotationfactor" },
      { label: "Moeda de cotação", key: "referencecurrency" },
      { label: "Indicador de correção de preço", key: "pricecorrectionindicator" },
    ],
  },
  {
    key: "Negociação",
    headers: [
      { label: "Número de negócios (XML)", key: "numberoftradesxml" },
      { label: "Quantidade negociada", key: "quantitytraded" },
      { label: "Volume financeiro", key: "financialtraded" },
      { label: "Data expiração da Opção", key: "optionexpiration" },
      { label: "Dias Expiração do Termo", key: "daysexpirationterm" },
      { label: "Ponto Strike", key: "strikepoint" },
      { label: "Preço Strike", key: "strikeprice" },
      { label: "Preço Strike Corrigido", key: "correctedstrikeprice" },
      { label: "Oferta Compra", key: "bestbuyofferprice" },
      { label: "Oferta Compra Corrigido", key: "correctedbestbuyofferprice" },
      { label: "Oferta Venda", key: "bestsaleofferprice" },
      { label: "Oferta Venda Corrigido", key: "correctedbestsaleofferprice" },
      { label: "Quantidade Alugada", key: "quantitytradedbtc" },
      { label: "Volume financeiro alugado", key: "financialtradedbtc" },
    ],
  },
  {
    key: "Preços",
    headers: [
      { label: "Abertura", key: "openingprice" },
      { label: "Fechamento", key: "closingprice" },
      { label: "Mínimo", key: "minimumprice" },
      { label: "Médio", key: "averageprice" },
      { label: "Máximo", key: "maximumprice" },
      { label: "Abertura Corrigido", key: "correctedopeningprice" },
      { label: "Fechamento Corrigido", key: "correctedclosingprice" },
      { label: "Mínimo Corrigido", key: "correctedminimumprice" },
      { label: "Médio Corrigido", key: "correctedaverageprice" },
      { label: "Máximo Corrigido", key: "correctedmaximumprice" },
      { label: "% Oscilação", key: "correctedoscillation" },
    ]
  }
];

interface Option {
  value: string,
  label: ReactElement | string
};

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

interface Header {
  label: string,
  key: string
};

const Extractor: FunctionComponent<Props> = (props: Props) => {

  const { firstDate, latestDate } = props.dates;

  const [loading, setLoading] = useState(false);
  const [extractedData, setExtractedData] = useState<Array<any>>([]);

  const [assetState, setAssetState] = useState("asset");
  const [assetData, setAssetData] = useState<Array<Option>>([]);
  const [nodeData, setNodeData] = useState<Array<any>>([]);

  const [selectedDate, setSelectedDate] = useState<Array<any>>([firstDate, latestDate]);

  const [csvHeaders, setCSVHeaders] = useState<Array<Header>>([
    { label: "Data do pregão", key: "tradingfloordate" },
    { label: "Código do Ativo", key: "tradingcode" },
    { label: "Número de negócios (XML)", key: "numberoftradesxml" },
    { label: "Volume financeiro", key: "financialtraded" },
    ...possibleHeaders[2].headers
  ]);

  const getCheckboxIndex = (option: Header) => {
    const index = csvHeaders.findIndex(element => element.key === option.key);
    return index;
  }

  const onCheckboxClick = (option: Header) => {
    const index = getCheckboxIndex(option);
    const isChecked = index !== -1;

    let newHeaders = [...csvHeaders];

    if (isChecked) {
      newHeaders.splice(index, 1);
    } else {
      newHeaders.push(option);
    }
    setCSVHeaders([...newHeaders]);
  }

  useEffect(() => {
    Promise.all([
      StockApiProvider.get('assets/'),
      StockApiProvider.get('class/listasativos_/')
    ])
      .then(r => {
        const assets = r[0].data;
        const lists = r[1].data;

        if (assets.length && lists.length) {
          const tmp_options: Option[] = assets.map((asset: any) => ({
            value: asset.tradingcode,
            label: (
              <>
                <Tag>{asset.tradingcode}</Tag>
                <span>{asset.shortname}</span>
              </>
            )
          }));
          setAssetData([...tmp_options]);
          setNodeData([...lists]);
        }

      })
      .catch(err => { });
  }, [latestDate]);

  const makeTreeData = (unprocessed_data: Array<any>): Array<any> => {

    let tree: Array<any> = [];

    for (let i = 0; i < unprocessed_data.length; i++) {
      const node = unprocessed_data[i];
      tree.push({
        title: node.value ? `${node.name}: ${node.value}` : `${node.name}`,
        value: node.id,
        children: makeTreeData(node.children)
      })
    }

    return tree;
  }

  const onFinish = (values: any) => {
    const start_date: string = moment(values.daterange[0]._d).format('YYYY-MM-DD');
    const end_date: string = moment(values.daterange[1]._d).format('YYYY-MM-DD');
    const table: string = values.table;

    let query_url = `data/?start=${start_date}&end=${end_date}&table=${table}&${assetState}=`
    
    if (assetState === 'asset') {
      query_url += values.asset;
    }  
    else {
      query_url += values.assetlist;
    }

    setLoading(true);
    StockApiProvider.get(query_url)
      .then(r => {
        const { data } = r;
        console.log(data);
        if (data.length > 0) message.success('Pesquisa finalizada. CSV pode ser baixado.');
        else message.info('Nenhum dado encontrado');
        setExtractedData([...data]);
        setSelectedDate([start_date, end_date]);
        setLoading(false);
      });
  };

  return (
    <>
      <Form
        colon={false}
        onFinish={onFinish}
        style={{ width: 300 }}>
        <Form.Item
          name="daterange"
          initialValue={[moment(firstDate), moment(latestDate)]}
          rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
          <DatePicker.RangePicker
            ranges={{
              'Hoje': [moment(), moment()],
              'Mês atual': [moment().startOf('month'), moment().endOf('month')],
              'Ano atual': [moment().startOf('year'), moment().endOf('year')],
            }}
            format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="asset"
          rules={[{ required: assetState === "asset", message: 'Por favor inserir o código BDI' }]}>
          <Select
            disabled={assetState !== "asset"}
            onMouseEnter={() => setAssetState("asset")}
            showSearch
            showArrow={false}
            placeholder="Código Ativo"
            optionFilterProp="value"
            filterOption={(input, option: any) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {assetData.map((option: Option) => {
              return <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="assetlist"
          rules={[{ required: assetState === "asset_list", message: 'Por favor selecionar a lista de ativos' }]}>
          <TreeSelect
            disabled={assetState !== "asset_list"}
            onMouseEnter={() => setAssetState("asset_list")}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={makeTreeData(nodeData)}
            placeholder="Lista de ativos"
          />
        </Form.Item>

        <Form.Item
          name="table"
          rules={[{ required: true, message: 'Por favor selecionar a tabela' }]}>
          <Select placeholder="Tabela">
            <Select.Option value="dailycote">DailyCote</Select.Option>
            <Select.Option value="dailycoteotherbusiness">DailyCoteOtherBusiness</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary"
            loading={loading}>
            Pesquisar
          </Button>
        </Form.Item>
      </Form>

      <Row gutter={8}>

        {possibleHeaders.map(category => (
          <Col span={8} key={category.key}>
            <Typography.Title level={4}>
              {category.key}
            </Typography.Title>

            <div style={{ display: 'inline-block' }}>
            {category.headers.map(option => (
              <Checkbox
                style={{ display: "block", marginLeft: 0 }}
                key={option.key}
                checked={getCheckboxIndex(option) !== -1}
                onClick={() => onCheckboxClick(option)}>
                {option.label}
              </Checkbox>
            ))}
            </div>
          </Col>
        ))}

        <Button
          disabled={extractedData.length === 0}
          type="primary"
          icon={<DownloadOutlined />}>
          <CSVLink
            data={extractedData}
            headers={csvHeaders}
            filename={`Data_${selectedDate[0]}_${selectedDate[1]}.csv`}
            style={{color: 'inherit'}}>
              Download
          </CSVLink>
        </Button>

      </Row>
    </>
  );
}

export default Extractor;
