import React, {
  FunctionComponent,
  useState,
  useEffect
} from 'react';

import moment from 'moment';

import {
  Layout,
  Menu,
  Avatar,
  Tag,
} from 'antd';
import {
  CloudDownloadOutlined, // CARGA DE DADOS
  DownloadOutlined, // EXTRAÇÃO DE DADOS
  ApartmentOutlined, // MANUTENÇAO META
  DollarCircleOutlined, // NEGOCIAÇOES
  LineChartOutlined, // VARIAÇOES
  RiseOutlined, // EVOLUCAO VARIACOES
  StockOutlined, // EVOLUÇAO ATIVO
  SlidersOutlined, // BANDAS DE BOLLINGER
  ColumnHeightOutlined, // TOPOS E FUNDOS
  MonitorOutlined, // ANÁLISE PELA OSCILAÇÃO
  BlockOutlined, // CORRELAÇÃO ENTRE ATIVOS
  BorderInnerOutlined, // CRUZAMENTO DE MEDIAS
  PicCenterOutlined, // ROMPIMENTO DE CONGESTAO
  PercentageOutlined, // ANÁLISE BTC
  TeamOutlined, // EVENTOS CORPORATIVOS
  DatabaseOutlined, // TRATAMENTO DE DADOS (SUB)
  FileMarkdownOutlined, // DOCUMENTACAO
  BorderTopOutlined, // CONGESTAO
  ExportOutlined, // EXPORTACAO PARA BULL
  FilterOutlined, // FILTRO DE ATIVOS

  DotChartOutlined,
  UserOutlined,
  BoxPlotOutlined,
  DollarOutlined
} from '@ant-design/icons';

import Importer from './Importer';
import Extractor from './Extractor';
import Metadata from './Metadata';
import Trades from './Trades';
import Variations from './Variations';
import VariationEvolution from './VariationEvolution';
import AssetEvolution from './AssetEvolution';
import Bollinger from './Bollinger';
import TopsAndBottoms from './TopsAndBottoms';
import Oscillation from './Oscillation';
import Correlations from './Correlations';
import AverageCrossing from './AverageCrossing';
import CongestionDisruption from './CongestionDisruption';
import BTCAnalysis from './BTCAnalysis';
import CorporateEvents from './CorporateEvents';
import CorrelatedPairs from './CorrelatedPairs';
import Congestion from './Congestion';
import Documentation from './Documentation';
import BullExport from './BullExport';
import RentabilitySim from './RentabilitySim';
import AssetFilter from './AssetFilter';

import OldCorrelations from './Correlations/old';
import OldAverageCrossing from './AverageCrossing/old';

import { connect, ConnectedProps } from 'react-redux';

const { Header, Footer, Sider, Content } = Layout;

const mapState = (state: any) => ({ dates: state.load });
const mapDispatch = {
  getLoad: () => ({ type: 'REQUEST_GET_LOAD' })
}

const connector = connect(mapState, mapDispatch)

type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {}

const Panel: FunctionComponent<Props> = (props: Props) => {

  const { getLoad } = props;

  const tabs = [
    {
      key: "asset_analisys",
      submenu: ["trades", "asset_evolution",]
    },
    {
      key: "indicator_analysis",
      submenu: ["bollinger", "average_crossing", "congestion_disruption", "congestion", "btc_analysis"]
    },
    {
      key: "oscillation_analysis",
      submenu: ["variations", "variation_evolution", "tops_and_bottoms", "oscillation"]
    },
    {
      key: "correlation_analysis",
      submenu: ["correlations", "correlatedpairs"]
    },
    {
      key: "results_analysis",
      submenu: ["rentabilitysim"]
    },
    {
      key: "data_manip",
      submenu: ["importer", "extractor", "metadata", "documentation", "corporate_events", "bullexport", "assetfilter"]
    },
    {
      key: "sub_old_screens",
      submenu: ["old_crossing", "old_correlations"],
    },
  ];

  const tabMapping: any = {
    "asset_analisys": {
      title: "Análise por Ativo",
      icon: <StockOutlined />,
    },
    "indicator_analysis": {
      title: "Análise por Indicador",
      icon: <DotChartOutlined />,
    },
    "oscillation_analysis": {
      title: "Análise por Oscilação",
      icon: <RiseOutlined />,
    },
    "correlation_analysis": {
      title: "Análise por Correlação",
      icon: <BoxPlotOutlined />,
    },
    "results_analysis": {
      title: "Análise de resultado",
      icon: <SlidersOutlined />,
    },
    "data_manip": {
      title: "Dados",
      icon: <DatabaseOutlined />,
    },
    "sub_old_screens": {
      title: "Telas antigas",
      icon: <TeamOutlined />,
    },
    "rentabilitysim": {
      title: "Simulação de rentabilidade",
      icon: <DollarOutlined />,
      component: <RentabilitySim dates={props.dates}/>
    },

    "importer": {
      title: "Carga de dados",
      icon: <CloudDownloadOutlined />,
      component: <Importer />
    },
    "extractor": {
      title: "Extração de dados",
      icon: <DownloadOutlined />,
      component: <Extractor dates={props.dates}/>
    },
    "metadata": {
      title: "Manutenção de metadados",
      icon: <ApartmentOutlined />,
      component: <Metadata />
    },
    "documentation": {
      title: "Documentação",
      icon: <FileMarkdownOutlined />,
      component: <Documentation />
    },
    "bullexport": {
      title: "Exportação para Bull",
      icon: <ExportOutlined />,
      component: <BullExport />
    },
    "assetfilter": {
      title: "Filtro de Ativos",
      icon: <FilterOutlined />,
      component: <AssetFilter dates={props.dates}/>
    },

    "trades": {
      title: "Negociações",
      icon: <DollarCircleOutlined />,
      component: <Trades dates={props.dates}/>
    },
    "variations": {
      title: "Maiores variações",
      icon: <LineChartOutlined />,
      component: <Variations dates={props.dates}/>
    },
    "variation_evolution": {
      title: "Evolução da variação",
      icon: <RiseOutlined />,
      component: <VariationEvolution dates={props.dates}/>
    },
    "asset_evolution": {
      title: "Evolução do ativo",
      icon: <StockOutlined />,
      component: <AssetEvolution dates={props.dates}/>
    },
    "bollinger": {
      title: "Bandas de Bollinger",
      icon: <SlidersOutlined />,
      component: <Bollinger dates={props.dates}/>
    },
    "tops_and_bottoms": {
      title: "Topos e Fundos",
      icon: <ColumnHeightOutlined />,
      component: <TopsAndBottoms dates={props.dates}/>
    },
    "oscillation": {
      title: "Análise pela Oscilação",
      icon: <MonitorOutlined />,
      component: <Oscillation dates={props.dates}/>
    },
    "correlations": {
      title: "Correlação entre ativos",
      icon: <BlockOutlined />,
      component: <Correlations dates={props.dates}/>
    },
    "average_crossing": {
      title: "Cruzamento de médias",
      icon: <BorderInnerOutlined />,
      component: <AverageCrossing dates={props.dates}/>
    },
    "congestion_disruption": {
      title: "Rompimento de congestão",
      icon: <PicCenterOutlined />,
      component: <CongestionDisruption dates={props.dates}/>
    },
    "btc_analysis": {
      title: "Análise BTC",
      icon: <PercentageOutlined />,
      component: <BTCAnalysis dates={props.dates}/>
    },
    "corporate_events": {
      title: "Eventos Corporativos",
      icon: <TeamOutlined />,
      component: <CorporateEvents dates={props.dates}/>
    },
    "correlatedpairs": {
      title: "Pares Correlacionados",
      icon: <BlockOutlined />,
      component: <CorrelatedPairs dates={props.dates}/>
    },
    "congestion": {
      title: "Ações em congestão",
      icon: <BorderTopOutlined />,
      component: <Congestion dates={props.dates}/>
    },

    "old_crossing": {
      title: "Cruzamento de médias",
      icon: <BorderInnerOutlined />,
      component: <OldAverageCrossing dates={props.dates}/>
    },
    "old_correlations": {
      title: "Correlação entre ativos",
      icon: <BlockOutlined />,
      component: <OldCorrelations dates={props.dates}/>
    },
  }

  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState<string>("importer");

  const handleMenuClick = (e: any) => {
    const { key } = e;
    setCurrentTab(key);
  }

  useEffect(() => {
    getLoad();
  }, [getLoad]);

  const isProduction = process.env.REACT_APP_ENVIROMENT === 'production';
  const tagColor = isProduction ? '#108ee9' : '#f50';

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        breakpoint="lg"
        collapsed={menuCollapsed}
        onCollapse={setMenuCollapsed}>
        <Menu
          theme="dark"
          selectedKeys={[currentTab]}
          mode="inline"
          onClick={handleMenuClick}>
          {tabs.map((tab: any) => {
            const mapping = tabMapping[tab.key];
            if ('submenu' in tab) {
              return (
                <Menu.SubMenu
                  key={tab.key}
                  icon={mapping.icon}
                  title={mapping.title}
                >
                  {tab.submenu.map((stab_key: any) => (
                    <Menu.Item key={stab_key} icon={tabMapping[stab_key].icon}>
                      {tabMapping[stab_key].title}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            }
            return (
              <Menu.Item key={tab.key} icon={mapping.icon}>
                {mapping.title}
              </Menu.Item>
            );

          })}
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ padding: 16, background: '#fff' }}>
          <Avatar icon={<UserOutlined />} style={{ float: 'right' }} />
        </Header>
        <Content
          style={{
            background: '#fff',
            margin: 16,
            padding: 24,
            minHeight: 280,
          }}>
          {tabMapping[currentTab].component}
        </Content>
        <Footer
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <span>Carga atualizada em {moment(props.dates.latestDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
          
          <span><Tag color={tagColor}>{process.env.REACT_APP_VERSION}</Tag></span>
        </Footer>
      </Layout>
    </Layout>
  );
}

export default connector(Panel);
