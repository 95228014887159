import React, {
  FunctionComponent,
  useState,
} from 'react';

import {
  Form,
  Checkbox,
  DatePicker,
  Button,
  Table,
  InputNumber,
  Select,
  message,
  Row,
  Col,
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import moment from 'moment';

import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from "react-csv";

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

const AverageCrossing: FunctionComponent<Props> = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [stockData, setStockData] = useState<Array<any>>([]);

  const { latestDate } = props.dates;

  const formItemLayout =
  !dataLoaded
    ? {
        labelCol: { span: 16 },
        wrapperCol: { span: 16 },
      }
    : null;

  const columns: any = [
    {
      title: 'Posição',
      render: (text: any, record: any, index: any) => index + 1,
      fixed: 'left',
    },
    {
      title: 'Ativo',
      dataIndex: 'tradingcode',
      fixed: 'left',
    },
    {
      title: 'Ratio',
      dataIndex: 'ratio',
      sorter: (a: any, b: any) => a.ratio - b.ratio,
      render: (value: number) => value.toFixed(4),
    },
    {
      title: 'Volume',
      dataIndex: 'volume',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'RSI',
      dataIndex: 'rsi',
      sorter: (a: any, b: any) => a.rsi - b.rsi,
    },
    {
      title: 'Ult. Cot.',
      dataIndex: 'closingprice',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'Ult. Osc.',
      dataIndex: 'oscillation',
      render: (value: number) => `${value}%`,
    },
  ];

  const onFinish = (values: any) => {
    console.log(values);

    const {
      avg1, avg2,
      offset1, offset2,
      date, avgvolume, corrected, field
    } = values;

    const query_url = `
    new_averagecrossing/?date=${moment(date).format('YYYY-MM-DD')}
    &avg1=${avg1}&offset1=${offset1}
    &avg2=${avg2}&offset2=${offset2}
    &field=${field}
    &volume=${avgvolume}
    &corrected=${corrected}`.replace(/\s\s+/g, '').trim();

    console.log(query_url)

    setLoading(true);
    StockApiProvider.get(query_url)
      .then((r: any) => {
        const { data } = r;
        if (data.length === 0)
          message.info('Nenhum dado encontrado');
        else
          message.success('Pesquisa finalizada');
        setStockData([...data]);
        setLoading(false);
        setDataLoaded(true);
      }).catch(() => {
        message.error('Não foram encontrados cruzamentos de médias')
        setLoading(false);
      });
  };

  return (
    <>
      <Form
        {...formItemLayout}
        onFinish={onFinish}
        layout={dataLoaded ? "inline" : "vertical"}>
        <Form.Item
          name="date"
          label="Data de corte"
          initialValue={moment(latestDate)}
          rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
          <DatePicker
            format="DD/MM/YYYY" />
        </Form.Item>

        {[1, 2].map((value: number) => (
        <Form.Item key={value}>
        <Row gutter={8} style={{maxWidth: 400}}>
          <Col span={12}>
          <Form.Item
              name={"avg" + value}
              label={"Dias média " + value}
              initialValue={21}
              rules={[{ required: true, message: 'Por favor preencher dias média' }]}>
              <InputNumber style={{width: '100%'}} min={1} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={"offset" + value}
              label="Offset"
              initialValue={0}
              rules={[{ required: true, message: 'Por favor preencher o offset' }]}>
              <InputNumber style={{width: '100%'}} min={0} />
            </Form.Item>
          </Col>
        </Row>
        </Form.Item>
        ))}

        <Form.Item
          name="avgvolume"
          label="Volume médio acima de"
          initialValue={1000000}
          rules={[{ required: true, message: 'Por favor preencher o volume médio' }]}>
          <InputNumber min={1000} />
        </Form.Item>

        <Form.Item name="corrected" valuePropName="checked" initialValue={true}>
          <Checkbox>Preço corrigido</Checkbox>
        </Form.Item>

        <Form.Item
          name="field"
          label="Preço"
          initialValue={'closingprice'}
          rules={[{ required: true, message: 'Por favor preencher o campo de preço' }]}>
          <Select style={{ width: 120 }}>
            <Select.Option value="openingprice">Abertura</Select.Option>
            <Select.Option value="closingprice">Fechamento</Select.Option>
            <Select.Option value="minimumprice">Mínimo</Select.Option>
            <Select.Option value="averageprice">Médio</Select.Option>
            <Select.Option value="maximumprice">Máximo</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary">
            Pesquisar
        </Button>
        </Form.Item>
      </Form>
      {(dataLoaded && stockData) &&
      <Table
        title={() => (
          <Button
            disabled={stockData.length === 0}
            type="primary"
            icon={<DownloadOutlined />}>
            <CSVLink data={stockData} filename={"AverageCrossing.csv"} style={{color: 'inherit'}}>Download</CSVLink>
          </Button>
        )}
        loading={loading}
        dataSource={stockData}
        columns={columns}
        scroll={{ x: 1300, y: 500 }}/>
      }
    </>
  );
}

export default AverageCrossing;
