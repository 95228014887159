import React, {
  FunctionComponent,
  useState,
} from 'react';

import {
  Form,
  Checkbox,
  DatePicker,
  Button,
  Table,
  InputNumber,
  message
} from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';

import moment from 'moment';

import StockApiProvider from '../../../providers/StockApiProvider';
import { CSVLink } from "react-csv";

interface Props {
  dates: {
    firstDate: string,
    latestDate: string,
  }
};

const Oscillation: FunctionComponent<Props> = (props: Props) => {

  const [loading, setLoading] = useState(false);
  const [stockData, setStockData] = useState<Array<any>>([]);

  const { latestDate } = props.dates;

  // Headers for React-CSV
  const headers = [
    { label: 'Trading Code', key: 'tradingcode' },
    { label: 'Short Name', key: 'shortname' },
    { label: 'Average Volume', key: 'avg_volume' },
    { label: 'RSI', key: 'rsi' },
    { label: 'Closing Price', key: 'closingprice' },
    { label: 'Oscillation', key: 'oscillation' },
    { label: 'Osc. Abs. Média', key: 'calculated_oscillation.avg_abs' },
    { label: 'Soma Osc. Abs.', key: 'calculated_oscillation.abs_sum' },
    { label: 'Soma Osc. Real', key: 'calculated_oscillation.real_sum' },
    { label: 'Osc. Max/Min Média', key: 'calculated_oscillation.avg_maxmin' },
    { label: 'Osc. Max/Min', key: 'calculated_oscillation.maxmin_sum' },
  ];

  const columns: any = [
    {
      title: 'Posição',
      render: (text: any, record: any, index: any) => index + 1,
      fixed: 'left',
    },
    {
      title: 'Ativo',
      dataIndex: 'tradingcode',
      fixed: 'left',
    },
    {
      title: 'Nome',
      dataIndex: 'shortname',
    },
    {
      title: 'Max./Fech.',
      dataIndex: 'ratio_max_closing',
      sorter: (a: any, b: any) => a.ratio_max_closing - b.ratio_max_closing,
    },
    {
      title: 'Fech./Min',
      dataIndex: 'ratio_closing_min',
      sorter: (a: any, b: any) => a.ratio_closing_min - b.ratio_closing_min,
    },
    {
      title: 'Osc. Abs. Média',
      dataIndex: ['calculated_oscillation', 'avg_abs'],
      sorter: (a: any, b: any) => a.calculated_oscillation.avg_abs - b.calculated_oscillation.avg_abs,
    },
    {
      title: 'Soma Osc. Abs.',
      dataIndex: ['calculated_oscillation', 'abs_sum'],
      sorter: (a: any, b: any) => a.calculated_oscillation.abs_sum - b.calculated_oscillation.abs_sum,
    },
    {
      title: 'Soma Osc. Real',
      dataIndex: ['calculated_oscillation', 'real_sum'],
      sorter: (a: any, b: any) => a.calculated_oscillation.real_sum - b.calculated_oscillation.real_sum,
    },
    {
      title: 'Osc. Max/Min Média',
      dataIndex: ['calculated_oscillation', 'avg_maxmin'],
      sorter: (a: any, b: any) => a.calculated_oscillation.avg_maxmin - b.calculated_oscillation.avg_maxmin,
    },
    {
      title: 'Osc. Max/Min',
      dataIndex: ['calculated_oscillation', 'maxmin_sum'],
      sorter: (a: any, b: any) => a.calculated_oscillation.maxmin_sum - b.calculated_oscillation.maxmin_sum,
    },
    {
      title: 'Volume Médio',
      dataIndex: 'avg_volume',
      render: (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    },
    {
      title: 'RSI',
      dataIndex: 'rsi',
      sorter: (a: any, b: any) => a.rsi - b.rsi,
    },
    {
      title: 'Ult. Cot',
      dataIndex: 'closingprice',
      render: (value: number) => `R$ ${value.toFixed(2)}`.replace(".", ","),
    },
    {
      title: 'Ult. Osc',
      dataIndex: 'oscillation',
      sorter: (a: any, b: any) => a.oscillation - b.oscillation,
      render: (value: number) => `${value.toFixed(2)} %`,
    },
  ];

  const onFinish = (values: any) => {
    console.log(values);

    const date: string = moment(values.date).format('YYYY-MM-DD');
    const corrected: boolean = values.corrected;
    const totalassets: number = values.totalassets;
    const avgvolume: number = values.avgvolume;

    setLoading(true);
    StockApiProvider.get(`oscillation/?date=${date}&total=${totalassets}&volume=${avgvolume}&corrected=${corrected}`)
      .then((r: any) => {
        const { data } = r;
        if (data.length === 0)
          message.info('Nenhum dado encontrado');
        else
          message.success('Pesquisa finalizada');
        console.log(data);
        setStockData([...data]);
        setLoading(false);
      }).catch(() => setLoading(false));
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        layout="inline"
        style={{ height: '60px' }}>
        <Form.Item
          name="date"
          label="Data de corte"
          initialValue={moment(latestDate)}
          rules={[{ required: true, message: 'Por favor preencher a data desejada' }]}>
          <DatePicker
            format="DD/MM/YYYY" />
        </Form.Item>

        <Form.Item
          name="totalassets"
          label="Total Pregões"
          initialValue={20}
          rules={[{ required: true, message: 'Por favor preencher o total de pregões' }]}>
          <InputNumber min={2} />
        </Form.Item>

        <Form.Item
          name="avgvolume"
          label="Volume médio acima de"
          initialValue={1000000}
          rules={[{ required: true, message: 'Por favor preencher o volume médio' }]}>
          <InputNumber min={1000} />
        </Form.Item>

        <Form.Item name="corrected" valuePropName="checked" initialValue={true}>
          <Checkbox>Preço corrigido</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            htmlType="submit"
            icon={<SearchOutlined />}
            type="primary">
            Pesquisar
        </Button>
        </Form.Item>
      </Form>

      <Table
        title={() => (
          <Button
            disabled={stockData.length === 0}
            type="primary"
            icon={<DownloadOutlined />}>
            <CSVLink data={stockData} headers={headers} filename={"Oscillation.csv"} style={{color: 'inherit'}}>Download</CSVLink>
          </Button>
        )}
        loading={loading}
        dataSource={stockData}
        columns={columns}
        scroll={{ x: 1300, y: 500 }}/>
    </>
  );
}

export default Oscillation;
